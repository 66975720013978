import SlideFromBottom from "../animations/SlideFromBottom";
import Brands from "../aboutus/brands";
import AboutTop from "../aboutus/abouttop";
import SecAbout from "../aboutus/secAbout";
import DarkBgTeam from "../aboutus/darkbgteam";

function AboutUs() {
  return (
    <div
      className="w-[100%] mt-[70px] flex flex-col dark:bg-[#010125] duration-200 items-center gap-y-20 max-md:gap-y-10 pt-[50px] pb-[100px]
    max-lg:py-10"
    >
      <div className="w-[100%] max-w-[1519px] px-[10%] max-md:px-[6%] flex flex-col justify-center gap-y-20 max-md:gap-y-10">
        <SlideFromBottom>
          <h1
            className="text-[#010125] w-full text-center py-[10px] text-[32px] dark:text-white duration-200 
            max-md:text-2xl max-sm:text-xl"
          >
            პლატფორმის შესახებ
          </h1>
        </SlideFromBottom>
        <AboutTop />
        <SecAbout />
      </div>
      <DarkBgTeam />
      <div className="w-[100%] max-w-[1519px] px-[10%] flex flex-col justify-center">
        <Brands />
      </div>
    </div>
  );
}

export default AboutUs;
