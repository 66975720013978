import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function ForgetPassword() {
  const [show, setshow] = useState(false);

  const handleshow = () => {
    setshow((pre) => !pre);
  };
  return (
    <div className="w-full flex flex-col gap-y-[20px]">
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ელ-ფოსტა</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">კოდი</p>
        <div className="flex w-[100%] h-[50px] border-[1px] rounded-[10px] items-center pr-[10px] gap-[10px]">
          <input
            type={show ? "text" : "password"}
            name="password"
            id="password"
            placeholder="***********"
            className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer" />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ახალი პაროლი</p>
        <div className="flex w-[100%] h-[50px] border-[1px] rounded-[10px] items-center pr-[10px] gap-[10px]">
          <input
            type={show ? "text" : "password"}
            name="password"
            id="password"
            placeholder="***********"
            className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer " />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-gradient-to-r from-[#85276a] to-[#013057] hover:from-white  text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#15154c] border-[2px] border-[#4d1dc1] dark:bg-white duration-200">
        <h1>რეგისტრაცია</h1>
      </div>
    </div>
  );
}
