import { useEffect, useRef, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import SignIn from "./signin";
import NewAccount from "./newaccount";
import ForgetPassword from "./forgetpassword";

function LoginRegister({ setLogreg, handleLogreg }) {
  const outlogregedRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (
      outlogregedRef.current &&
      !outlogregedRef.current.contains(event.target)
    ) {
      setLogreg(false);
    }
  }

  const [active, setActive] = useState("signin");

  return (
    <div className="loginreg w-[100%] h-[100vh] fixed top-0 left-0 z-50 flex py-[40px] justify-center bg-[#000000b0] overflow-y-auto max-sm:p-5">
      <div
        ref={outlogregedRef}
        className={`loginregpopup rounded-[15px] w-[500px] self-start bg-white ${
          active === "signin" || active === "forgetpassword" ? "mt-[70px]" : ""
        }`}
      >
        <div className="logregpopuptop duration-200 px-[30px] py-[20px] border-b-[1px] flex justify-between items-center">
          <h1 className="logregpoptitle font-semibold text-[19px] text-[#010125]">
            სისტემაში შესვლა
          </h1>
          <div
            className="w-[40px] h-[40px] rounded-full flex items-center bg-gray-100 justify-center text-[#010125] myownbackgrhover hover:text-white duration-200 cursor-pointer"
            onClick={handleLogreg}
          >
            <XLg />
          </div>
        </div>
        <div className="logregcontent duration-200 px-[30px] py-[20px] flex flex-col gap-y-[20px]">
          <div className="flex">
            <h1
              className={`avtoreg px-[8px] py-[10px] cursor-pointer border-black ${
                active === "signin"
                  ? "border-b-[2px] text-black"
                  : "text-gray-400 hover:text-black"
              }`}
              onClick={() => {
                setActive("signin");
              }}
            >
              ავტორიზაცია
            </h1>
            <h1
              className={`avtoreg px-[8px] py-[10px] cursor-pointer border-black ${
                active === "newacc"
                  ? "border-b-[2px] text-black"
                  : "text-gray-400 hover:text-black"
              }`}
              onClick={() => {
                setActive("newacc");
              }}
            >
              რეგისტრაცია
            </h1>
          </div>

          {active === "signin" && (
            <SignIn active={active} setActive={setActive} />
          )}
          {active === "newacc" && <NewAccount />}
          {active === "forgetpassword" && <ForgetPassword />}
        </div>
      </div>
    </div>
  );
}

export default LoginRegister;
