import AccordionOneItem from "../accordion/AccordionOneItem";
import SlideFromBottom from "../animations/SlideFromBottom";
import Lottie from "react-lottie";

import One from "../LottieAnimations/2z34E6fxGc.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScreenSize from "../customHooks/useScreenSize";

export default function NoteSecur({goToTopFast}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: One,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const screenSize = useScreenSize();

  const [imageDimensions, setImageDimensions] = useState({
    x: 900,
    y: 900,
  });

  useEffect(() => {
    if (screenSize >= 1280) {
      setImageDimensions({
        y: 900,
        x: 900,
      });
    } else if (screenSize >= 1140 && screenSize <= 1280) {
      setImageDimensions({
        y: 800,
        x: 800,
      });
    } else if (screenSize >= 990 && screenSize <= 1140) {
      setImageDimensions({
        x: 700,
        y: 700,
      });
    } else if (screenSize >= 950 && screenSize <= 990) {
      setImageDimensions({
        x: 600,
        y: 600,
      });
    } else if (screenSize >= 750 && screenSize <= 950) {
      setImageDimensions({
        x: 1000,
        y: 1000,
      });
    } else if (screenSize >= 580 && screenSize <= 750) {
      setImageDimensions({
        x: 900,
        y: 900,
      });
    } else if (screenSize >= 400 && screenSize <= 580) {
      setImageDimensions({
        x: 600,
        y: 650,
      });
    } else if (screenSize >= 325 && screenSize <= 400) {
      setImageDimensions({
        x: 500,
        y: 500,
      });
    } else if (screenSize >= 0 && screenSize <= 325) {
      setImageDimensions({
        x: 400,
        y: 400,
      });
    }
  }, [screenSize]);
  // end screen width

  const [questions, setQuestion] = useState([
    {
      question: "მობილური აპლიკაციები წარმოადგენს მნიშვნელოვან მიმართულებას",
      answer:
        "პროგრამული უზრუნველყოფის ბაზარზე ახალი მომხმარებლების, გაყიდვებისა და კომპანიის იმიჯის გაზრდის ერთ-ერთ ყველაზე პერსპექტიულ მეთოდს. მობილური აპლიკაცია - ეს არის ახალი შესაძლებლობა თქვენი ბიზნესისთვის ციფრულ სამყაროში.",
      opend: false,
      card: "one",
    },
    {
      question: "მობილური აპლიკაცია ორიენტირება მომხმარებლის კომფორტზე",
      answer:
        "მობილური აპლიკაციის შექმნით კომპანია აჩვენებს, რომ ზრუნავს თავის მომხმარებლებზე. აპლიკაციის არსებობა მომხმარებლის კომფორტზე ორიენტირებული ნაბიჯია.",
      opend: false,
      card: "two",
    },
    {
      question: "მობილური აპლიკაციის საშუალებით თქვენი კომპანია შეძლებს:",
      answer: "",
      tone: "ცნობადობის გაზრდას",
      ttwo: "კომპანიის ბრენდის პოპულარიზაციას",
      tfree: "პოტენციურ კლიენტებთან დაახლოებას",
      tfour: "გაყიდვების ზრდას",
      tfive: "ფულადი სარგებლის მიღებას",
      par: "აპლიკაცია მზადდება შემდეგ პლატფორმებზე:",
      teone: "Android",
      tetwo: "IOS",
      opend: false,
      card: "three",
    },
  ]);

  //change refclickes
  const filterQuestions = (index) => {
    const newQuestions = questions.map((item, i) => {
      if (i === index) {
        if (questions[index].opend === true) {
          return { ...item, opend: false };
        } else {
          return { ...item, opend: true };
        }
      } else {
        return { ...item, opend: false };
      }
    });
    setQuestion(newQuestions);
  };

  return (
    <div className="notesmaindiv w-[100%] flex  justify-between gap-[20px] items-center">
      <div className="notetextpart flex w-[45%] flex-col gap-y-[30px] dark:text-white">
        <SlideFromBottom>
          <h1 className="notemaintitle text-[28px] duration-200">
            არ გაქვს მობილური აპლიკაცია არ ხარ ბიზნესში
          </h1>
        </SlideFromBottom>
        <SlideFromBottom>
          <div>
            {questions.map((item, index) => {
              return (
                <AccordionOneItem
                  key={index}
                  index={index}
                  question={item.question}
                  answer={item.answer}
                  tone={item.tone}
                  ttwo={item.ttwo}
                  tfree={item.tfree}
                  tfour={item.tfour}
                  tfive={item.tfive}
                  par={item.par}
                  teone={item.teone}
                  tetwo={item.tetwo}
                  opend={item.opend}
                  filterQuestions={filterQuestions}
                  card={item.card}
                />
              );
            })}
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <Link to="/contact">
            <div
              className="notebtn flex w-[250px] gap-[10px] duration-150 rounded-[33px] items-center justify-center cursor-pointer myownbackgr text-white border-[1px] border-gray-300 dark:border-0   py-[12px] self-start"
              onClick={goToTopFast}
            >
              <h1>შეუკვეთე ახლავე</h1>
            </div>
          </Link>
        </SlideFromBottom>
      </div>
      <SlideFromBottom style={"noteimagepart w-[50%] h-[600px]"}>
        <div className="w-full h-full pointer-events-none flex items-center justify-center">
          <Lottie
            options={defaultOptions}
            height={imageDimensions.y}
            width={imageDimensions.x}
          />
        </div>
      </SlideFromBottom>
    </div>
  );
}
