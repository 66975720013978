import { useContext } from "react";
import { PrimaryContext } from "../MainContext/MainContext";

const Waves = () => {
  const { darkModeValue } = useContext(PrimaryContext);
  return (

    <div className="w-full h-[100%] overflow-hidden relative">
      <div
        className={`w-[250vw] h-[250vw] absolute top-[52%] left-[-60%] bg-transparent
            rounded-[38%_42%] border-[1px]  mix-blend-multiply
            animate-[vawespin_16s_infinite_linear] ${darkModeValue === "dark" ? "border-[white]" : "border-[#85276a]"}`}
      ></div>
      <div
        className={`w-[250vw] h-[250vw] absolute top-[60%] left-[-50%] bg-transparent
            rounded-[38%_42%] border-[1px] mix-blend-multiply
            animate-[vawespin_16s_infinite_linear]  ${darkModeValue === "dark" ? "border-[white]" : "border-[#013057]"}`}
      ></div>
    </div>
  );
};

export default Waves;
