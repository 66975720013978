import { BiSearch } from "react-icons/bi";
import { BsCheckCircle, BsCartCheck } from "react-icons/bs";
import cartImage from "../../imgs/cart-empty.svg";

function InnerHosting() {
  return (
    <div className="mt-[70px] flex flex-col dark:bg-[#010125] duration-200 items-center">
      <div className="servtoptitle w-[100%] flex justify-center items-center">
        <div
          className="w-[100%] flex flex-col items-center justify-center bg-[#00000094]
          bg-gradient-to-r from-[#85276a]  to-[#013057] text-white p-20 max-sm:p-8"
        >
          <h1 className="text-lg">მოძებნე ომენი მარტივად</h1>
          <div className="bg-white rounded-lg flex w-6/12 p-2 mt-5 max-sm:w-full">
            <input
              type="text"
              className="w-full h-10 bg-white pl-5 rounded-lg border-0 outline-0 text-black"
            />
            <div className="bg-gradient-to-r from-[#85276a]  to-[#013057] w-10 h-10 p-2 rounded-lg">
              <BiSearch className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className={"w-full flex gap-10 px-[10%] max-md:px-[6%] py-20 max-md:py-10"}>
        <div className="w-8/12 max-lg:w-full">
          <div
            className={
              "border border-gray-300 shadow-md bg-white rounded-2xl p-5 flex items-center justify-between"
            }
          >
            <div className="flex items-center gap-2">
              <div
                className={
                  "h-6 p-1 rounded-full bg-gradient-to-r from-[#85276a]  to-[#013057] flex gap-1 items-center px-2"
                }
              >
                <BsCheckCircle
                  className={"text-white h-full w-full font-bold"}
                />
                <p className={"text-[10px] font-semibold text-white mt-1"}>
                  ხელმისაწვდომია
                </p>
              </div>
              <span className="ont-bold text-gray-800 font-semibold">
                qweqwee.ge
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="ont-bold text-gray-800 font-semibold">150$</span>
              <div className={"w-8 h-8 p-1 rounded-lg bg-gradient-to-r from-[#85276a]  to-[#013057]"}>
                <BsCartCheck className={"text-white h-full w-full font-bold"} />
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full mt-6">
            <div class="flex items-center justify-between mb-6">
              <h2 class="text-base md:text-xl full-uppercase px-4 md:px-0 font-bold text-gray-500">
                მსგავსი დომენები
              </h2>
            </div>
            <div class="bg-white rounded-2xl px-4 md:px-5 md:p-4 border border-gray-300 shadow-md relative">
              <div class="flex h-20 md:h-auto py-4 items-center justify-between flex-wrap border-b border-gray-100 opacity-100">
                <div class="font-bold text-gray-800 self-start md:self-autp flex items-center opacity-100">
                  <div className="flex items-center gap-2">
                    <div
                      className={
                        "h-6 p-1 rounded-full bg-gradient-to-r from-[#85276a]  to-[#013057] flex gap-1 items-center px-2"
                      }
                    >
                      <BsCheckCircle
                        className={"text-white h-full w-full font-bold"}
                      />
                      <p className={"text-[10px] font-semibold text-white mt-1"}>
                        ხელმისაწვდომია
                      </p>
                    </div>
                    <span>aerqwe.com.ge</span>
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <p class="absolute md:static">
                    12.00 <span class="text-sm">₾</span>
                  </p>
                  <div className={"w-8 h-8 p-1 rounded-lg bg-gradient-to-r from-[#85276a]  to-[#013057]"}>
                    <BsCartCheck
                      className={"text-white h-full w-full font-bold"}
                    />
                  </div>
                </div>
              </div>
              <div class="flex h-20 md:h-auto py-4 items-center justify-between flex-wrap border-b border-gray-100 opacity-100">
                <div class="font-bold text-gray-800 self-start md:self-autp flex items-center opacity-100">
                  <div className="flex items-center gap-2">
                    <div
                      className={
                        "h-6 p-1 rounded-full bg-gradient-to-r from-[#85276a]  to-[#013057] flex gap-1 items-center px-2"
                      }
                    >
                      <BsCheckCircle
                        className={"text-white h-full w-full font-bold"}
                      />
                      <p className={"text-[10px] font-semibold text-white mt-1"}>
                        ხელმისაწვდომია
                      </p>
                    </div>
                    <span>aerqwe.com.ge</span>
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <p class="absolute md:static">
                    12.00 <span class="text-sm">₾</span>
                  </p>
                  <div className={"w-8 h-8 p-1 rounded-lg bg-gradient-to-r from-[#85276a]  to-[#013057]"}>
                    <BsCartCheck
                      className={"text-white h-full w-full font-bold"}
                    />
                  </div>
                </div>
              </div>
              <div class="flex h-20 md:h-auto py-4 items-center justify-between flex-wrap border-b border-gray-100 opacity-100">
                <div class="font-bold text-gray-800 self-start md:self-autp flex items-center opacity-100">
                  <div className="flex items-center gap-2">
                    <div
                      className={
                        "h-6 p-1 rounded-full bg-gradient-to-r from-[#85276a]  to-[#013057] flex gap-1 items-center px-2"
                      }
                    >
                      <BsCheckCircle
                        className={"text-white h-full w-full font-bold"}
                      />
                      <p className={"text-[10px] font-semibold text-white mt-1"}>
                        ხელმისაწვდომია
                      </p>
                    </div>
                    <span>aerqwe.com.ge</span>
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <p class="absolute md:static">
                    12.00 <span class="text-sm">₾</span>
                  </p>
                  <div className={"w-8 h-8 p-1 rounded-lg bg-gradient-to-r from-[#85276a]  to-[#013057]"}>
                    <BsCartCheck
                      className={"text-white h-full w-full font-bold"}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="w-4/12 relative max-lg:fixed max-lg:bg-black max-lg:bg-opacity-50 max-lg:w-screen max-lg:h-screen 
        max-lg:flex max-lg:items-center max-lg:justify-center max-lg:top-0 max-lg:left-0  max-lg:p-5">
          <div
            className={
              "w-full border border-gray-300 shadow-md bg-white rounded-2xl p-5 flex flex-col sticky top-28 left-0 max-lg:w-auto"
            }
          >
            <p class="text-base md:text-xl full-uppercase px-4 md:px-0 font-bold text-gray-500">
              ჩემი კალათი
            </p>
            <div className="w-full flex flex-col items-center justify-center gap-2">
              <img src={cartImage} class="mt-4 w-32" alt="#" />
              <p class="text-base full-uppercase px-4 md:px-0 font-bold text-gray-500 text-center">
                კალათა ცარიელია
              </p>
              <p class="text-[12px] px-4 md:px-0 text-gray-500 text-center">
                მარტივად მოძებნეთ სასურველი დომენი, რომელსაც რამდენიმე წუთში დაარეგისტრირებთ
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InnerHosting;
