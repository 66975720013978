import { useState } from "react";

export default function ContactService() {
  const [data, setData] = useState({
    firsName: "",
    mail: "",
    mobNumber: "",
  });

  const handlechange = (e) => {
    if (e.target.name === "mobNumber" && e.target.value > data.mobNumber) {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/\s/g, "-")
        .replace(/(.{3})/g, "$1-")
        .trim()
        .slice(0, 11);
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="w-[100%] flex bg-gray-100 rounded-[20px] p-[20px] shadow-xl max-lg:flex-col max-sm:p-2">
      <div className="w-[65%] max-lg:w-full flex flex-col justify-center items-center">
        <h1 className="text-[40px] max-sm:text-[24px]">დაგვიკავშირდით...</h1>
      </div>
      <div
        className="w-[35%] max-lg:w-full px-[20px] py-[30px] flex flex-col items-center 
      gap-y-[15px] shadow-xl rounded-[20px] bg-white max-sm:p-4"
      >
        <h1 className="text-[26px] font-semibold">მოგვწერეთ</h1>
        <div className="flex flex-col w-[100%] gap-y-[10px]">
          <p className="font-semibold text-[#010125]">სახელი</p>
          <input
            name="firsName"
            value={data.firsName}
            onChange={(e) => handlechange(e)}
            type="text"
            className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
          />
        </div>
        <div className="flex flex-col w-[100%] gap-y-[10px]">
          <p className="font-semibold text-[#010125]">ელ-ფოსტა</p>
          <input
            name="mail"
            value={data.mail}
            onChange={(e) => handlechange(e)}
            type="text"
            className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
          />
        </div>
        <div className="flex flex-col w-[100%] gap-y-[10px]">
          <p className="font-semibold text-[#010125]">ტელეფონი</p>
          <input
            name="mobNumber"
            value={data.mobNumber}
            onChange={(e) => handlechange(e)}
            type="text"
            className="numberinput w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
          />
        </div>
        <h1 className="w-[100%] h-[60px] cursor-pointer flex items-center justify-center rounded-[20px] myownbackgr text-white">
          დადასტურება
        </h1>
      </div>
    </div>
  );
}
