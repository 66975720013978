import { BiSearch } from "react-icons/bi";
import HostingSlider from "./HostingSlider";
import { BsLaptop } from "react-icons/bs";

function Hosting() {
  return (
    <div className="mt-[70px] flex flex-col dark:bg-[#010125] duration-200 items-center">
      <div className="servtoptitle w-[100%] flex justify-center items-center ">
        <div
          className="w-[100%] flex flex-col items-center justify-center bg-[#00000094]
         bg-gradient-to-r from-[#85276a]  to-[#013057] text-white p-20 max-sm:p-8"
        >
          <h1 className="text-lg">მოძებნე ომენი მარტივად</h1>
          <div className="bg-white rounded-lg flex w-6/12 p-2 mt-5 max-sm:w-full">
            <input
              type="text"
              className="w-full h-10 bg-white pl-5 rounded-lg border-0 outline-0 text-black"
            />
            <div className="bg-gradient-to-r from-[#85276a]  to-[#013057] w-10 h-10 p-2 rounded-lg">
              <BiSearch className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className={"w-full px-[10%] max-md:px-[6%] py-20 max-md:py-10"}>
        <HostingSlider />
      </div>

      <div
        className={
          "w-full mb-10 px-[10%] max-md:px-[6%] py-20 max-md:py-10 pt-0"
        }
      >
        <div class="grid grid-cols-1 pb-8 items-end">
          <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Hosting Services
          </h3>
          <p class="text-slate-400 max-w-xl">
            Create, collaborate, and turn your ideas into incredible products
            with the definitive platform for digital design.
          </p>
        </div>
        <div
          className={
            "grid grid-cols-3 gap-10 max-md:gap-5 max-lg:grid-cols-2 max-sm:grid-cols-1"
          }
        >
          {[1, 2, 3, 4, 5, 6].map(() => {
            return (
              <div class="relative p-6 shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden">
                <div class="relative overflow-hidden text-transparent -m-3">
                  <div
                    className={
                      "bg-gradient-to-r from-[#85276a]  to-[#013057] rounded-full p-2 w-12 h-12"
                    }
                  >
                    <BsLaptop className="w-full h-full text-white" />
                  </div>
                  <div class="absolute top-2/4 -translate-y-2/4 start-8 text-indigo-600 rounded-xl  transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                    <i class="uil uil-server"></i>
                  </div>
                </div>
                <div class="mt-6">
                  <p class="text-lg font-medium transition-all duration-500 ease-in-out">
                    Web Hosting
                  </p>
                  <p class="text-slate-400  transition-all duration-500 ease-in-out mt-3">
                    The phrasal sequence of the is now so that many campaign and
                    benefit
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Hosting;
