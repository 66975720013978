import { Heart } from "react-bootstrap-icons";
import Lottie from "react-lottie";
import One from "../LottieAnimations/3QY3rsfuno.json";
import SlideFromLeft from "../animations/SlideFromLeft";
import SlideFromRight from "../animations/SlideFromRight";
import SlideFromBottom from "../animations/SlideFromBottom";
import { useEffect, useState } from "react";
import { TfiTime } from "react-icons/tfi";
import { FaConnectdevelop } from "react-icons/fa";
import { BsCreditCard2Back, BsDiagram3 } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import useScreenSize from "../customHooks/useScreenSize";

function Whatwedo() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: One,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const screenSize = useScreenSize();

  const [imageDimensions, setImageDimensions] = useState({
    x: 500,
    y: 500,
  });

  useEffect(() => {
    if (screenSize >= 1450) {
      setImageDimensions({
        x: 390,
        y: 380,
      });
    } else if (screenSize >= 1200 && screenSize <= 1450) {
      setImageDimensions({
        x: 450,
        y: 360,
      });
    } else if (screenSize >= 1150 && screenSize <= 1200) {
      setImageDimensions({
        x: 400,
        y: 300,
      });
    } else if (screenSize >= 0 && screenSize <= 1150) {
      setImageDimensions({
        x: 450,
        y: 450,
      });
    } else if (screenSize >= 0 && screenSize <= 0) {
      setImageDimensions({
        x: 300,
        y: 250,
      });
    }
  }, [screenSize]);
  // end screen width
  return (
    <div className="w-[100%] flex justify-center">
      <div className="watchpart flex flex-col gap-y-[20px]">
        <SlideFromBottom>
          <div className="flex flex-col items-center">
            <h1 className="watchtitle text-[30px] text-center myownbackgr inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200 mb-[30px]">
              პლატფორმის უპირატესობები
            </h1>
          </div>
        </SlideFromBottom>
        <div className="watchcont grid grid-cols-3 gap-y-[60px] w-[100%] justify-between items-center">
          <div className="watchcol w-[100%] h-[100%] flex flex-col gap-y-[60px] dark:text-gray-300 duration-200">
            <SlideFromLeft>
              <div className="watchmaincard wmcup flex gap-[20px] group">
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-right">
                  <h1 className="watchcardtitletop text-[18px]">
                    დროის ენერგიის და ხარჯების დაზოგვა
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    პლატფორმის მიზანია დაუზოგოს ბიზნესს ენერგია და რესურსები მზა
                    ვებ-გვერდის შეთავაზებით
                  </p>
                </div>

                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white  bg-white duration-200">
                  <TfiTime />
                </div>
              </div>
            </SlideFromLeft>

            <SlideFromLeft>
              <div className="watchmaincard wmcup flex gap-[20px] group">
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-right">
                  <h1 className="watchcardtitletop text-[18px]">
                    ინტეგრაციები
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    საბუღალტრო, სააღრიცხვო, საკურიერო და გადახდის სისტემებთან
                    ინტეგრაციით BMSOFT-ი ეფექტურად წარმოგაჩენთ და ჩაგრთავთ
                    ელ-კომერციაში
                  </p>
                </div>
                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white bg-white duration-200">
                  <BsDiagram3 />
                </div>
              </div>
            </SlideFromLeft>
            <SlideFromRight>
              <div className="watchmaincard wmcup flex gap-[20px] group">
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-right">
                  <h1 className="watchcardtitletop text-[18px]">
                    გადახდის რამდენმე მეთოდი
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    BMSOFT-ის პლათფორმა გთავაზობთ გადახდის 5 მეთოდს
                  </p>
                </div>
                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white bg-white duration-200">
                  <BsCreditCard2Back />
                </div>
              </div>
            </SlideFromRight>
          </div>

          <div className="watchimgcol pointer-events-none w-[100%] h-[300px] flex items-center justify-center">
            <Lottie
              options={defaultOptions}
              width={imageDimensions.x}
              height={imageDimensions.y}
            />
          </div>

          <div className="watchcol h-[100%] w-[100%] flex flex-col gap-y-[60px] dark:text-gray-300 duration-200">
            <SlideFromLeft>
              <div className="watchmaincard flex gap-[20px] group">
                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white bg-white duration-200">
                  <Heart />
                </div>
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-left">
                  <h1 className="watchcardtitletop text-[18px]">
                    მისაღები ფასები შემოთავაზებულ პაკეტებზე
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    BMSOFT-ის პლატფორმის შეძენისას ჩვენ გთავაზობთ სხვადასახვა
                    პაკეტებს და მისაღებ ფასებს
                  </p>
                </div>
              </div>
            </SlideFromLeft>

            <SlideFromRight>
              <div className="watchmaincard flex gap-[20px] group">
                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white  bg-white duration-200">
                  <FaConnectdevelop />
                </div>
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-left">
                  <h1 className="watchcardtitletop text-[18px]">
                    მოქნილობა და სიმარტივე
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    ვებ-გვერდის მოქნილობა საშუალებას მოგცემთ შეესაბამებოდეს იგი
                    თქვენს ბრენდს და მომხმარებელთა მოთხოვნებს
                  </p>
                </div>
              </div>
            </SlideFromRight>

            <SlideFromRight>
              <div className="watchmaincard flex gap-[20px] group">
                <div className="watchcardicn w-[60px] h-[60px] rounded-[18px] text-[23px] flex items-center justify-center border-shadow text-[#010125] group-hover:bg-gradient-to-r from-[#85276a]  to-[#013057] group-hover:text-white bg-white duration-200">
                  <BiSupport />
                </div>
                <div className="watchcard flex flex-col gap-y-[10px] w-[80%] text-left">
                  <h1 className="watchcardtitletop text-[18px]">
                    24/7 მხარდაჭერა
                  </h1>
                  <p className="watchpartext text-gray-400 text-[17px]">
                    ტექნიკური მხარდაჭერა და უწყვეტი კავშირი მომხმარებელთა
                    მხარდაჭერის გუნდთან
                  </p>
                </div>
              </div>
            </SlideFromRight>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whatwedo;
