import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { useLocation} from "react-router-dom";

function FifthCard({ img, title, text, serv, anim, he, wi, goToTopFast, link }) {
  const location = useLocation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className={`w-[100%] flex gap-[40px] dark:text-gray-300 relative
    ${
      location.pathname === "/"
        ? "portfolimaindivii px-[10px]"
        : "px-[10px] max-lg:flex-col"
    }`}
    >
      <div
        className={` h-[400px] rounded-[10px] flex justify-center items-center py-[10px] ${
          location.pathname === "/services"
            ? "w-[50%] pointer-events-none max-lg:w-full max-lg:h-[300px] max-sm:h-[200px] max-extrasm:h-[150px]"
            : "portfimg w-[65%] relative"
        }`}
      >
        {location.pathname === "/services" ? (
          <Lottie options={defaultOptions} height={he} width={wi} />
        ) : (
          <img
            src={img}
            className="w-[100%] h-[100%] object-cover rounded-[20px] big-gray-shadow border-[3px] border-white dark:border-[0px]"
          />
        )}
        <div
          className={`${
            location.pathname === "/services"
              ? "hidden"
              : "onimagediv hidden w-[100%] h-[93%] border-[3px] border-transparent bg-[#00000a42] absolute rounded-[20px]"
          }`}
        ></div>
      </div>
      <div
        className={` flex flex-col gap-y-[10px] pb-[20px]   justify-center ${
          location.pathname === "/services"
            ? "w-[50%] items-start max-xl:order-2 max-lg:w-full gap-y-[30px]"
            : "portfoltextpart w-[35%] items-start"
        }`}
      >
        <div className="relative flex flex-col items-center ">
          <h1
            className={`text-[#010125] py-[10px] text-[30px] dark:text-white duration-200 max-md:text-2xl max-sm:text-xl ${
              location.pathname === "/services" ? "" : "portftitle text-start"
            }`}
          >
            {title}
          </h1>
        </div>
        <p className={` ${serv ? "flex servcardtext text-left" : "hidden"}`}>
          {text}
        </p>

        <Link to={link}>
          <div
            className={`w-[100%] ${
              location.pathname === "/services" ? "justify-end flex" : "hidden"
            } `}
            
          >
            <h1
              className="gap-[3px] duration-200 w-[130px] h-[40px] rounded-[33px] text-[#010125] 
              myownbackgrhover hover:text-white border-[1px] border-gray-300 dark:border-[0px] text-[14px] bg-white flex items-center justify-center cursor-pointer"
          onClick={goToTopFast}>
              მეტის ნახვა
            </h1>
          </div>
        </Link>

        <div
          className={`w-[100%] ${
            location.pathname === "/services" ? "hidden" : "mainbotmore justify-start flex"
          } `}
        >
          <Link>
            <h1
              className="gap-[3px] duration-200 w-[140px] h-[40px] rounded-[33px] text-[#010125] 
              myownbackgrhover hover:text-white border-[1px] border-gray-300 dark:border-[0px] bg-white flex items-center justify-center cursor-pointer"
            >
              მეტის ნახვა
            </h1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FifthCard;
