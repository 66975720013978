import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/output.css";
import "./styles/main.css";
import "./styles/responcive.css";
import "swiper/css";
import { BrowserRouter } from "react-router-dom";
import { MainContext } from "./components/MainContext/MainContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MainContext>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MainContext>
  </React.StrictMode>
);

