import { useEffect, useRef, useContext } from "react";
import { XLg } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import sitelogo from "../imgs/400.webp";
import logodark from "../imgs/300.webp";
import {
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
} from "react-bootstrap-icons";
import { PrimaryContext } from "./MainContext/MainContext";

function DropDownMenu({ handleDropmenu, setDropmenu, goToTopFast,dropmenu }) {
  //dark mode value
  const { darkModeValue } = useContext(PrimaryContext);

  const menuRef = useRef(null);
  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // click occurred outside of wrapper, close the div here
      setDropmenu(false);
    }
  }
  return (
      <div
        ref={menuRef}
        className={`fixed top-0 right-0 dropmenudown w-[280px] h-screen bg-white p-[16px] dark:bg-[#010125] flex flex-col gap-y-[16px]
        rounded-l-lg transition-all duration-200 ease-linear delay-100 border-l border-gray-300 shadow-sm 
        ${dropmenu ? "translate-x-0 opacity-100" : "translate-x-[100%] opacity-0"}`}
      >
        <div className="flex items-center justify-between">
            <img src={darkModeValue !== "dark" ? sitelogo : logodark} alt="#" className={"w-[150px]"}/>
          <div
            className="w-8 h-8 p-2 flex justify-center items-center cursor-pointer rounded-full
            bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] text-white"
            onClick={handleDropmenu}
          >
            <XLg className={"w-full h-full"}/>
          </div>
        </div>
        <div className="h-[100%] mt-20">
          <ul className="font-semibold text-[17px] flex flex-col">
            <Link to="/" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
           
              <li
                className="cursor-pointer duration-200"
                onClick={() => {
                  goToTopFast();
                  handleDropmenu();
                }}
              >
                მთავარი
              </li>
            </Link>
            <Link to="pricing" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
          
            <li
              className="cursor-pointer duration-200"
              onClick={() => {
                goToTopFast();
                handleDropmenu();
              }}
            >
              პროდუქტი
            </li>
            </Link>
            <Link to="blog" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
          
              <li
                className="cursor-pointer duration-200"
                onClick={() => {
                  goToTopFast();
                  handleDropmenu();
                }}
              >
                ბლოგი
              </li>
            </Link>
            <Link to="services" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
          
              <li
                className="cursor-pointer duration-200"
                onClick={() => {
                  goToTopFast();
                  handleDropmenu();
                }}
              >
                სერვისები
              </li>
            </Link>
            <Link to="aboutus" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
          
              <li
                className="cursor-pointer duration-200"
                onClick={() => {
                  goToTopFast();
                  handleDropmenu();
                }}
              >
                ჩვენ შესახებ
              </li>
            </Link>
            <Link to="contact" className={"flex items-center gap-2 py-2 border-b border-gray-300"}>
          
            <li
              className="cursor-pointer duration-200"
              onClick={() => {
                goToTopFast();
                handleDropmenu();
              }}
            >
              კონტაქტი
            </li>
            </Link>
          </ul>
        </div>
        <div className="flex gap-[10px]">
              <div className="w-[30px] h-[30px] rounded-full shadow-md border border-gray-300 cursor-pointer
               flex items-center justify-center myownbackgrhover duration-200 bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] 
               text-white">
                <Facebook />
              </div>
              <div className="w-[30px] h-[30px] rounded-full shadow-md border border-gray-300  cursor-pointer
               flex items-center justify-center myownbackgrhover duration-200 bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] 
               text-white">
                <Linkedin />
              </div>
              <div className="w-[30px] h-[30px] rounded-full shadow-md border border-gray-300  cursor-pointer
               flex items-center justify-center myownbackgrhover duration-200 bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] 
               text-white">
                <Instagram />
              </div>
              <div className="w-[30px] h-[30px] rounded-full shadow-md border border-gray-300  cursor-pointer
               flex items-center justify-center myownbackgrhover duration-200 bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] 
               text-white">
                <Youtube />
              </div>
            </div>
      </div>
  );
}

export default DropDownMenu;
