import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import MainPage from "./components/pages/main";
import Footer from "./components/footer";
import DarkModeSwitcher from "./components/darkmode/DarkModeSwitcher";
import AboutUs from "./components/pages/aboutus";
import Services from "./components/pages/services";
import Pricing from "./components/pages/pricing";
import Blog from "./components/pages/blog";
import Contact from "./components/pages/contact";
import Hosting from "./components/domens/Hosting";
import InnerHosting from "./components/domens/InnerHosting";
import Instructions from "./components/instructions/instructions";
import InnerServicefive from "./components/pages/innerservicefive";
import InnerServicefour from "./components/pages/innerservicefour";
import InnerServicethree from "./components/pages/innerservicethree";
import InnerServicetwo from "./components/pages/innerservicetwo";
import InnerServiceone from "./components/pages/innerserviceone";

function App() {

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const goToTopSmooth = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <DarkModeSwitcher />
      <Header goToTopFast={goToTopFast} goToTopSmooth={goToTopSmooth}/>
      <Routes>
        <Route path="/" element={<MainPage goToTopFast={goToTopFast}/>} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="hosting" element={<Hosting />} />
        <Route path="inner-hosting" element={<InnerHosting />} />
        <Route path="instructions" element={<Instructions />} />
        <Route path="blog/*" element={<Blog />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services goToTopFast={goToTopFast}/>} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="loyalty-systems" element={<InnerServicefive/>}/>
        <Route path="payment-systems" element={<InnerServicefour/>}/>
        <Route path="counting-systems" element={<InnerServicethree/>}/>
        <Route path="mobile-applications-systems" element={<InnerServicetwo/>}/>
        <Route path="exclusive-design-systems" element={<InnerServiceone/>}/>
      </Routes>
      <Footer goToTopSmooth={goToTopSmooth}/>
    </>
  );
}

export default App;
