import { useSpring, animated } from 'react-spring';

const TiltEffect = ({children, mstyle}) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    // Initial state
    transform: 'perspective(500px) rotateX(0deg) rotateY(0deg)',
    config: { mass: 5, tension: 350, friction: 40 }
  }));

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const { left, top, width, height } = event.target.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;
    const xPercentage = (x / width) * 100;
    const yPercentage = (y / height) * 100;

    setSpringProps({
      // Apply the tilt effect based on mouse position
      transform: `perspective(500px) rotateX(${yPercentage / 5}deg) rotateY(${-xPercentage / 5}deg)`
    });
  };

  return (
    <animated.div
      className={`tilt-effect ${mstyle}`}
      onMouseMove={handleMouseMove}
      style={springProps}
    >
      {children}
    </animated.div>
  );
};

export default TiltEffect;