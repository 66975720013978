import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import BlogStart from "../blog/blogstart";
import EveryBlog from "../blog/inblog";

function Blog() {
  const location = useLocation();
  const navigate = useNavigate(null);

  useEffect(() => {
    if (location.pathname === "/blog") {
      navigate("/blog/allblogs");
    }
  }, [location]);

  return (
    <div className="blogpagemain mt-[70px] w-[100%] flex items-center flex-col gap-y-[30px]">
      <div
        className="blogpage  max-w-[1519px] px-20 w-[100%] pb-[30px] flex  gap-[10px] dark:bg-[#010125] duration-200
      max-lg:px-5"
      >
        <div className="w-full">
          <Routes>
            <Route path={"allblogs"} element={<BlogStart />}></Route>
            <Route path={"inblog"} element={<EveryBlog />}></Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Blog;
