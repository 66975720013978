import { useSpring, animated } from "react-spring";
import { useRef } from "react";
import useIntersectionObserver from "../customHooks/IntObserver";

const SlideFromLeft = ({ children , style, duration = 300}) => {
  //object ref
  const wholeDivRef = useRef(null);
  //ref intersection observer
  const dataRef = useIntersectionObserver(wholeDivRef, {
    freezeOnceVisible: true,
  });

  //animation
  const props = useSpring({
    config: { duration: duration },
    trail: 400,
    from: {
      opacity: 0,
      x: -20,
    },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      x: dataRef?.isIntersecting ? 0 : -20,
    },
  });

  return (
    <animated.div style={props} className={style}>
      <div ref={wholeDivRef} className="w-full">{children}</div>
    </animated.div>
  );
};

export default SlideFromLeft;
