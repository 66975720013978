import newpc from "../../imgs/6666.webp";
import new2 from "../../imgs/7777.webp";
import new3 from "../../imgs/8888.webp";
import new4 from "../../imgs/4444.webp";
import new5 from "../../imgs/5555.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css/effect-fade";
import React, { useEffect, useRef, useState } from "react";
import SlideFromBottom from "../animations/SlideFromBottom";
import ServicesCard from "./servicescard";
import useScreenSize from "../customHooks/useScreenSize";

function Offers() {
  let swiperRef = useRef();
  const [slideToDisplay, setSlidesToDisplay] = useState(0);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize >= 1380) {
      setSlidesToDisplay(5);
    } else if (screenSize <= 1380 && screenSize >= 1000) {
      setSlidesToDisplay(4);
    } else if (screenSize <= 1000 && screenSize >= 650) {
      setSlidesToDisplay(3);
    } else if (screenSize <= 650 && screenSize >= 420) {
      setSlidesToDisplay(2);
    } else if (screenSize <= 420 && screenSize >= 0) {
      setSlidesToDisplay(1);
    }
  }, [screenSize]);

  return (
    <div className="w-[100%] flex justify-center">
      <div className="servicespart w-[100%] flex flex-col gap-y-[40px]">
        <SlideFromBottom>
          <div className="servictitlediv flex flex-col items-center relative mb-[20px]">
            <h1 className="servicestitle text-center text-[30px] bg-gradient-to-r from-[#85276a] to-[#013057] inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200">
              დამატებითი სერვისები
            </h1>
          </div>
        </SlideFromBottom>
        <SlideFromBottom class={"w-full"}>
          <div className="servicesgap w-[100%] flex flex-wrap justify-between">
            <Swiper
              modules={[Autoplay, EffectFade, Pagination]}
              slidesPerView={slideToDisplay}
              slidesPerGroup={1}
              spaceBetween={20}
              pagination={{ clickable: true }}
              loop={true}
              className=""
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              disabledclass={"disabled_swiper_button"}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              speed={600}
              cssease={"linear"}
            >
              <SwiperSlide>
                <ServicesCard
                  img={newpc}
                  title={"ექსკლუზიური დიზაინის შექმნა"}
                  text={
                    "BMSOFT - ი დაგეხმარებათ ექსკლუზიური დიზაინის შექმნაში რაღათქმაუნდა თქვენი იდეების გათვალისწონებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new2}
                  title={"მობილური აპლიკაციის დამზადება"}
                  text={
                    "BMSOFT - ი გთავაზობთ მობილური აპლიკაციის დამზადებას თქვენი საიტისთვის თქვენი გემოვნების გათვალისწინებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new3}
                  title={"ინტეგრაცია სააღრიცხვო პროგრამასთან"}
                  text={
                    "BMSOFT - ის პლატფორმა ინტეგრირებულია სააღრიცხვო პროგრამა Optimo - სთან. მარტივად აღრიცხეთ მარაგები და თვალი ადევნეთ შემოსავლებს"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new4}
                  title={"გადახდის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - ის პლატფორმა გთავაზობთ ელ-კომერციისთვის ძალიან მნიშვნელოვანი საკითხის გადაჭრას, გადახდის სისტემებთან ინტეგრაციის საშუალებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new5}
                  title={"ლოიალობის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - მა თქვენი ბიზნესისთვის შეიმუშავა ძლიერი ლოიალობის სისტემა, როგორც ჩვენი პლატფორმის ნაწილი, რომელიც საშუალებას გაძლევთ წაახალისოთ თქვენი მომხმარებლები"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={newpc}
                  title={"ექსკლუზიური დიზაინის შექმნა"}
                  text={
                    "BMSOFT - ი დაგეხმარებათ ექსკლუზიური დიზაინის შექმნაში რაღათქმაუნდა თქვენი იდეების გათვალისწონებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new2}
                  title={"მობილური აპლიკაციის დამზადება"}
                  text={
                    "BMSOFT - ი გთავაზობთ მობილური აპლიკაციის დამზადებას თქვენი საიტისთვის თქვენი გემოვნების გათვალისწინებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new3}
                  title={"ინტეგრაცია სააღრიცხვო პროგრამასთან"}
                  text={
                    "BMSOFT - ის პლატფორმა ინტეგრირებულია სააღრიცხვო პროგრამა Optimo - სთან. მარტივად აღრიცხეთ მარაგები და თვალი ადევნეთ შემოსავლებს"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new4}
                  title={"გადახდის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - ის პლატფორმა გთავაზობთ ელ-კომერციისთვის ძალიან მნიშვნელოვანი საკითხის გადაჭრას, გადახდის სისტემებთან ინტეგრაციის საშუალებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new5}
                  title={"ლოიალობის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - მა თქვენი ბიზნესისთვის შეიმუშავა ძლიერი ლოიალობის სისტემა, როგორც ჩვენი პლატფორმის ნაწილი, რომელიც საშუალებას გაძლევთ წაახალისოთ თქვენი მომხმარებლები"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={newpc}
                  title={"ექსკლუზიური დიზაინის შექმნა"}
                  text={
                    "BMSOFT - ი დაგეხმარებათ ექსკლუზიური დიზაინის შექმნაში რაღათქმაუნდა თქვენი იდეების გათვალისწონებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new2}
                  title={"მობილური აპლიკაციის დამზადება"}
                  text={
                    "BMSOFT - ი გთავაზობთ მობილური აპლიკაციის დამზადებას თქვენი საიტისთვის თქვენი გემოვნების გათვალისწინებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new3}
                  title={"ინტეგრაცია სააღრიცხვო პროგრამასთან"}
                  text={
                    "BMSOFT - ის პლატფორმა ინტეგრირებულია სააღრიცხვო პროგრამა Optimo - სთან. მარტივად აღრიცხეთ მარაგები და თვალი ადევნეთ შემოსავლებს"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new4}
                  title={"გადახდის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - ის პლატფორმა გთავაზობთ ელ-კომერციისთვის ძალიან მნიშვნელოვანი საკითხის გადაჭრას, გადახდის სისტემებთან ინტეგრაციის საშუალებით"
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ServicesCard
                  img={new5}
                  title={"ლოიალობის სისტემების ინტეგრაცია"}
                  text={
                    "BMSOFT - მა თქვენი ბიზნესისთვის შეიმუშავა ძლიერი ლოიალობის სისტემა, როგორც ჩვენი პლატფორმის ნაწილი, რომელიც საშუალებას გაძლევთ წაახალისოთ თქვენი მომხმარებლები"
                  }
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </SlideFromBottom>
      </div>
    </div>
  );
}

export default Offers;
