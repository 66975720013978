import InstructionsCard from "./instructionCard";
import video from "../../imgs/video.mp4";
import SlideFromBottom from "../animations/SlideFromBottom";

const Instructions = () => {
  const slides = Array.from({ length: 20 }, (_, index) => (
    <SlideFromBottom>
      <InstructionsCard key={index} link={video} />
    </SlideFromBottom>
  ));
  return (
    <div className="mt-[70px] w-[100%] flex items-center flex-col gap-y-[30px] dark:bg-[#010125]">
      <div
        className="max-w-[1519px] w-[100%] pb-[30px] pt-[50px]  duration-200
        px-[10%] max-md:px-[6%] mb-20 max-md:my-0 "
      >
        <SlideFromBottom>
          <h1
            className="text-[#010125] w-full text-center py-[10px] text-[32px] dark:text-white duration-200 
            max-md:text-2xl max-sm:text-xl mb-10"
          >
            ინსტრუქციები
          </h1>
        </SlideFromBottom>
        <div className="w-full flex gap-10 max-lg:flex-col mt-10">
          <div className="w-7/12 relative max-lg:w-full">
            <SlideFromBottom>
              <div className="sticky top-40 left-0 w-full flex flex-col gap-[30px]">
                <video
                  width="100%"
                  height="100%"
                  className="rounded-lg lg:border lg:border-gray-300 lg:shadow-md"
                  controls
                >
                  <source src={video} type="video/mp4" />
                </video>
                <div className="w-full mt-4">
                  <h1 className="text-2xl dark:text-white">
                    კატეგორიების დამატება
                  </h1>
                  <p className="text-xl text-gray-500 mt-4">
                    ვიდეოში საკმაოდ დეტალურად არის აღწერილი იმ აქტივობების
                    ჩამონათვალი, რომლებიც აუცილებელია საკუთარი ონლაინ მაღაზიის
                    გასახსნელად.
                  </p>
                </div>
              </div>
            </SlideFromBottom>
          </div>
          <SlideFromBottom style="w-5/12 max-lg:w-full  ">
            <div
              className=" w-full lg:p-5 grid grid-cols-1 max-sm:grid-cols-1  max-lg:grid-cols-2 gap-[15px] 
          rounded-xl lg:border lg:border-gray-300 lg:shadow-md overflow-auto scrollbar-none h-[80vh]"
            >
              {slides}
            </div>
          </SlideFromBottom>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
