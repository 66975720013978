import Lottie from "react-lottie";
import One from "../LottieAnimations/0KJFKMZ6Su.json";
import Two from "../LottieAnimations/BXbgc9BvRG.json";
import Three from "../LottieAnimations/E7FPdtgA18.json";

function ContactPart(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: One,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      const defaultOptionss = {
        loop: true,
        autoplay: true,
        animationData: Two,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      const defaultOptionsss = {
        loop: true,
        autoplay: true,
        animationData: Three,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
    return(
        <div className="w-[100%] border-[1px] rounded-[70px] justify-between flex py-[20px] px-[30px] bg-white
        max-md:flex-col max-md:rounded-2xl max-md:px-[5%] max-md:py-[10px]">
            <div className="flex items-center gap-[10px] py-[10px]">
                <div className="w-[50px] h-[50px] pointer-events-none animate-pulse">
                <Lottie options={defaultOptions}/>
                </div>
                <p className="font-semibold">Sales@Bmsoft.dev</p>
            </div>
            <div className="border-r-[1px]">
                
            </div>
            <div className="flex items-center gap-[10px]  py-[10px]">
                <div  className="w-[50px] pointer-events-none h-[50px] animate-pulse	">
                <Lottie options={defaultOptionss}/>
                </div>
                <p className="font-semibold">წერეთლის გამზირი #116, თბილისი</p>
            </div>
            
            <div className="border-r-[1px]">
                
            </div>
            <div className="flex items-center gap-[10px]  py-[10px]">
                <div className="w-[50px] pointer-events-none h-[50px] animate-pulse">
                <Lottie options={defaultOptionsss}/>
                </div>
                <p className="font-semibold">2 11 11 90</p>
            </div>
        </div>
    )
}

export default ContactPart