import InServCard from "../servicespage/innerservices/inservcard";
import pht from "../../imgs/100.webp"
import pht2 from "../../imgs/200.webp";
import Waves from "../animations/Waves";
import SlideFromBottom from "../animations/SlideFromBottom";
import ContactService from "../servicespage/contactinservice";

export default function InnerServicetwo() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <div className="fixed w-[100%] h-[100%] top-0 left-0 z-[-20]">
        <Waves />
      </div>
      <div className="w-full mt-[70px] max-w-[1519px] px-[10%] flex flex-col py-20 max-sm:py-10 gap-y-20 max-sm:gap-y-10">
        <h1
          className="text-center text-[35px] text-[#010125]
                max-md:text-2xl max-sm:text-xl"
        >
          მობილური აპლიკაციის დამზადება
        </h1>
        <InServCard
          foto={pht}
          text={"BMSOFT პლატფორმის გარდა, ჩვენ გთავაზობთ მობილური აპლიკაციის დამზადებას, თქვენი საიტისთვის რომელიც დაგეხმარებათ თავი გააცნოთ უფრო ფართო აუდიტორიას. მობილური აპლიკაცია სულ უფრო პოპულარული და საჭირო საშუალება ხდება თანამედროვე ბიზნეს საქმიანობისთვის."}
          texttwo={"რატომ არის სასარგებლო მობილური აპლიკაცია ელექტრონული კომერციისთვის ?  მაქსიმალურად კომფორტული და მოქნილი აპლიკაცია გაგიადვილებთ ონლაინ მაღაზიის მართვას."}
          step={"მობილური აპლიკაცია - გააცანი თავი ფართო აუდიტორიას"}
        />
        <InServCard
          rev={true}
          foto={pht2}
          text={"მძლავრი და ამავდროულად მარტივი აპლიკაციის საშუალებით რომელსაც ჩვენ დაგიმზადებთ თქვენი გემოვნების და სურვილის გათვალისწინებით, მომხმარებლებს ექნებათ შემდეგი უპირატესობა"
          }
          texttwo={"უპრობლემო შოპინგი:"}
          textthree={"დაათვალიერებენ პროდუქტს და შეძლებენ ნახონ პროდუქტი სხვადასხვა კატეგორიების გაფილტვრის საშუალებით მარტივად. უპრობლემოდ გააკეთებენ ნივთის ან მომსახურების შესყიდვას პირდაპირ მობილური აპლიკაციიდან."}
          textfour={"ჩვენი ინტუიციური ინტერფეისი უზრუნველყოფს თქვენი მომხმარებლის სასიამოვნო სავაჭრო გამოცდილებას, რომელიც შესაბამისად დაეხმარება თქვენს ბიზნეს აუდიტორიის და მომხმარებლის რაოდენობის გაზრდაში. თუ გადაჭარბებული არ იქნება შეიძლება ასეც ითქვას."}
          step={"არ გაქვს მობილური აპლიკაცია არ ხარ ბიზნესში"}
        />
        <InServCard
          foto={pht}
          text={
            "თუ გსურთ  თქვენ ონლაინ მაღაზიის პლატფორმას ქონდეს განსხვავებული და გამორჩეული დიზაინი, BMSOFT დაგეხმარებათ ექსლუზიური დიზაინის შექმნაში, რაღათქმაუნდა თქვენი იდეების გათვალისწონებით."
          }
          step={"აპლიკაცია კომპანიის იმიჯის გაზრდის ყველაზე ეფექტური საშალება"}
        />
        <SlideFromBottom>
          <ContactService />
        </SlideFromBottom>
      </div>
    </div>
  );
}
