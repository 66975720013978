import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css/effect-fade";
import React, { useState, useEffect } from "react";
import SlideFromBottom from "../animations/SlideFromBottom";

import pht1 from "../../imgs/77.webp";
import pht2 from "../../imgs/78.webp";
import pht3 from "../../imgs/79.webp";
import pht4 from "../../imgs/80.webp";
import pht5 from "../../imgs/easyway.svg";
import pht6 from "../../imgs/optimo.svg";
import pht7 from "../../imgs/payze.svg";

import SliderComp from "./slidercomp";
import useScreenSize from "../customHooks/useScreenSize";

function Brands() {
  const [slideToDisplay, setSlidesToDisplay] = useState(0);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize >= 1380) {
      setSlidesToDisplay(6);
    } else if (screenSize <= 1200 && screenSize >= 1000) {
      setSlidesToDisplay(5);
    } else if (screenSize <= 1000 && screenSize >= 740) {
      setSlidesToDisplay(4);
    } else if (screenSize <= 740 && screenSize >= 640) {
      setSlidesToDisplay(3);
    } else if (screenSize <= 640 && screenSize >= 300) {
      setSlidesToDisplay(2);
    }
  }, [screenSize]);

  const [brands, setBrands] = useState([
    {
      id:1,
      img: pht1,
    },
    {
      id:2,
      img: pht2,
    },
    {
      id:3,
      img: pht3,
    },
    {
      id:4,
      img: pht4,
    },
    {
      id:5,
      img: pht5,
    },
    {
      id:6,
      img: pht6,
    },
    {
      id:7,
      img: pht7,
    },
    {
      id:8,
      img: pht1,
    },
    {
      id:9,
      img: pht2,
    },
    {
      id:10,
      img: pht3,
    },
    {
      id:11,
      img: pht4,
    },
    {
      id:12,
      img: pht5,
    },
    {
      id:13,
      img: pht6,
    },
    {
      id:14,
      img: pht7,
    },
    {
      id:15,
      img: pht1,
    },
    {
      id:16,
      img: pht2,
    },
    {
      id:17,
      img: pht3,
    },
    {
      id:18,
      img: pht4,
    },
    {
      id:19,
      img: pht5,
    },
    {
      id:20,
      img: pht6,
    },
    {
      id:21,
      img: pht7,
    },
  ]);

  return (
    <div className="flex flex-col gap-[40px] items-center">
      <h1
        className="text-[#010125] w-full text-center text-[32px] dark:text-white duration-200 
 max-md:text-2xl max-sm:text-xl"
      >
        პარტნიორები
      </h1>
      <div className="aboutusbotslider w-full flex justify-center">
        <SlideFromBottom style={"w-full"}>
          <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={slideToDisplay}
            spaceBetween={30}
            loop={true}
            className="mySwiper items-center flex"
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            disabledclass={"disabled_swiper_button"}
            speed={1500}
            cssease={"linear"}
          >
            {brands.map((item) => (
              <SwiperSlide key={item.id}>
                <SliderComp  img={item.img} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SlideFromBottom>
      </div>
    </div>
  );
}

export default Brands;
