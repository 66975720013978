import Questions from "./questions";
import SlideFromBottom from "../animations/SlideFromBottom";
import NewCardOne from "../mainpage/newcardone";
import { useState } from "react";
import PricingSlider from "./pricingSlider";
import { BsStars } from "react-icons/bs";

function Pricing() {
  const [month, setMonth] = useState(true);

  const [priority, setPriority] = useState([
    {
      title: "სრული წვდომა ფუნქციონალზე",
    },
    {
      title: "ქართულ და ინგლისურ ენოვანი სამართავი პანელი",
    },
    {
      title: "ვებ-გვერდის ნებისმიერ ენაზე შექმნის შესაძლებლობა",
    },
    {
      title: "კატეგორიების და კოლექციების მართვის შესაძლებლობა",
    },
    {
      title: "ფასდაკლებების მართვის დახვეწილი სისტემა",
    },
    {
      title: "ფეისბუქით და გუგლით ავტორიზირება",
    },

    {
      title: "ფეისბუქ მესენჯერის ინტეგრაცია",
    },
    {
      title: "გუგლ ანალიტიქსის ინტეგრაცია",
    },
    {
      title: "ფეისბუქ პიქსელის ინტეგრაცია",
    },

    {
      title: "გუგლის რუკის ინტეგრაცია",
    },
    {
      title: "ბლოგის შექმნის და მართვის შესაძლებლობა",
    },
    {
      title: "თავსებადობა ნებისმიერ დომენთან",
    },

    {
      title: "ოპტიმიზირება საძიებო სისტემებთან",
    },
    {
      title: "ოპტიმიზირება მობილური მოწყობილობებისთვის",
    },







    {
      title: "უფასო SSL სერთიფიკატი",
    },

    {
      title: "24/7 მხარდაჭერა",
    },
    {
      title: "მოიცავს ჰოსტინგის ღირებულებას ",
    },
    {
      title: "ვებ-სივრცის ლიმიტი - 20 გბ",
    },

    {
      title: "ინტეგრაცია ადგილობრივ საკურიერო კომპანიებთან",
    },
    {
      title: "ინტეგრაცია საბუღალტრო პროგრამასთან",
    },
    {
      title: "ინტეგრაცია გადახდის სისტემებთან",
    },

    {
      title: "გადახდის 5 მეთოდი:",
    },
    {
      title: "გადახდა ნაღდი ფულით",
    },
    {
      title: "გადახდა საბანკო ბარათით",
    },
    {
      title: "გადახდა საბანკო გადარიცხვით (ინვოისით)",
    },
    {
      title: "გადახდა ელექტრონული საფულით",
    },

    {
      title: "გადახდა საბანკო განვადებით",
    },
  ]);

  const handleMonth = () => {
    setMonth((pre) => !pre);
  };
  return (
    <div className="pricingmaindiv mt-[70px] flex items-center flex-col gap-y-10 dark:bg-[#010125] duration-200">
      <div className="w-[100%] max-w-[1519px] py-[50px] gap-y-10">
        <div>
          <SlideFromBottom>
            <h1
              className="text-[#010125] w-full text-center py-[10px] text-[32px] dark:text-white duration-200 
            max-md:text-2xl max-sm:text-xl mb-10"
            >
              პროდუქტი
            </h1>
          </SlideFromBottom>
          <SlideFromBottom>
            <PricingSlider />
          </SlideFromBottom>
        </div>
        <div className="px-[10%] max-md:px-[6%] py-20 max-md:py-10 mb-20 max-md:mb-10 myownbackgr">
          <SlideFromBottom>
            <h1 className="text-white w-full text-[32px] max-md:text-2xl max-sm:text-2xl text-center">
              უპირატესობები
            </h1>
          </SlideFromBottom>
          <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-5 mt-5">
            {priority.map((item, index) => {
              return (
                <SlideFromBottom>
                  <div key={index} className="flex items-center gap-2">
                    <BsStars className="w-4 h-4 text-white" />
                    <p className="text-[12px] text-white">{item.title}</p>
                  </div>
                </SlideFromBottom>
              );
            })}
          </div>
        </div>
        <div
          className={
            "flex flex-col items-center justify-center px-[10%] max-md:px-[6%]"
          }
        >
          <SlideFromBottom>
        <div className="flex flex-col items-center gap-y-[20px]">
          <h1 className="whybmtitle text-[30px] myownbackgr inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200">
            პაკეტები
          </h1>
        </div>
      </SlideFromBottom>
          <SlideFromBottom>
            <div className="flex justify-center py-[30px]">
              <div className="flex p-[5px] gray-shadow rounded-[33px] gap-[5px] bg-white">
                <p
                  className={`px-[15px] pb-[3px] rounded-[33px] cursor-pointer duration-200 ${month ? "myownbackgr text-white" : ""
                    }`}
                  onClick={handleMonth}
                >
                  თვე
                </p>
                <p
                  className={`px-[15px] pb-[3px] rounded-[33px] cursor-pointer duration-200 ${month ? "" : "myownbackgr text-white"
                    }`}
                  onClick={handleMonth}
                >
                  შესყიდვა
                </p>
              </div>
            </div>
          </SlideFromBottom>
          <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 max-lg:gap-10">
            <SlideFromBottom>
              <NewCardOne
                cardtitle={"სტარტაპი"}
                cotitle={
                  "Free 14 days trial, you can use over 20 basic features"
                }
                month={month}
                txt1={"5GB სივრცე"}
                txt2={"70% პერფორმანსი"}
                txt3={"უფასო ლიმიტირებული პლაგინები"}
                txt4={"SSL სერტიფიკატი"}
                txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
              />
            </SlideFromBottom>
            <SlideFromBottom>
              <NewCardOne
                cardtitle={"მედიუმი"}
                cotitle={
                  "Free 14 days trial, you can use over 20 basic features"
                }
                month={month}
                txt1={"10 GB სივრცე"}
                txt2={"85% პერფორმანსი"}
                txt3={"უფასო ლიმიტირებული პლაგინები"}
                txt4={"SSL სერტიფიკატი"}
                txt6={"Rs.ge ინტეგრაცია"}
                txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
              />
            </SlideFromBottom>
            <SlideFromBottom>
              <NewCardOne
                cardtitle={"ენტერპრაისი"}
                cotitle={
                  "Free 14 days trial, you can use over 20 basic features"
                }
                month={month}
                txt1={"20 GB სივრცე"}
                txt2={"99% პერფორმანსი"}
                txt3={"უფასო ლიმიტირებული პლაგინები"}
                txt4={"SSL სერტიფიკატი"}
                txt6={"Rs.ge ინტეგრაცია"}
                txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
              />
            </SlideFromBottom>
          </div>
        </div>
      </div>
      <div className="pricingpage w-[100%] max-w-[1519px] px-[10%] flex flex-col items-center justify-center gap-y-[80px]">
        <Questions />
      </div>
    </div>
  );
}

export default Pricing;
