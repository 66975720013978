import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css/effect-fade";
import React, { useEffect, useRef, useState } from "react";
import TeamMates from "./teammates";

import bero from "../../imgs/bero.webp";
import chiqo from "../../imgs/chiqo.webp";
import jaba from "../../imgs/005.webp";
import mee from "../../imgs/007.webp";
import dato from "../../imgs/dato.webp";
import beqa from "../../imgs/001.webp";
import silaga from "../../imgs/silaga.jpeg";
import teo from "../../imgs/teo.webp";
import lana from "../../imgs/006.webp";
import rexv from "../../imgs/rexv.jpeg";
import mariam from "../../imgs/mariam.jpeg";

import useScreenSize from "../customHooks/useScreenSize";
import SlideFromBottom from "../animations/SlideFromBottom";

export default function DarkBgTeam() {
  let swiperRef = useRef();
  const [slideToDisplay, setSlidesToDisplay] = useState(0);
  const screenSize = useScreenSize();
  useEffect(() => {
    if (screenSize >= 1380) {
      setSlidesToDisplay(5);
    } else if (screenSize <= 1380 && screenSize >= 1000) {
      setSlidesToDisplay(4);
    } else if (screenSize <= 1000 && screenSize >= 800) {
      setSlidesToDisplay(3);
    } else if (screenSize <= 800 && screenSize >= 620) {
      setSlidesToDisplay(2);
    } else if (screenSize <= 620 && screenSize >= 0) {
      setSlidesToDisplay(1);
    }
  }, [screenSize]);

  const [info, setInfo] = useState([
    {
      id: 1,
      link: bero,
      name: "გიორგი ბერიძე",
      pos: "Founder & CEO",
    },
    {
      id: 2,
      link: chiqo,
      name: "გიორგი ჩიქოვანი",
      pos: "Tech Lead",
    },
    {
      id: 3,
      link: jaba,
      name: "ჯამბულ ხაჩიძე",
      pos: "Head of Front-End Department",
    },
    {
      id: 4,
      link: mee,
      name: "ნიკოლოზ ანაშვილი",
      pos: "Senior Front-End Developer",
    },
    {
      id: 5,
      link: dato,
      name: "დავით ქარელი",
      pos: "Senior Back-End Developer",
    },
    {
      id: 6,
      link: beqa,
      name: "ბექა ბერიძე",
      pos: "Senior Back-End Developer",
    },
    {
      id: 7,
      link: silaga,
      name: "გიორგი სილაგაძე",
      pos: "Senior Front-End Developer",
    },
    {
      id: 8,
      link: teo,
      name: "თეონა ჭიღლაძე",
      pos: "Head of Marketing Department",
    },
    {
      id: 9,
      link: lana,
      name: "ლანა კიკნაძე",
      pos: "Project Manager",
    },
    {
      id: 10,
      link: rexv,
      name: "ნიკოლოზ რეხვიაშვილი",
      pos: "Grow Manager",
    },
    {
      id: 11,
      link: mariam,
      name: "მარიამ სვანიძე",
      pos: "UX/UI Designer",
    },
    {
      id: 12,
      link: bero,
      name: "გიორგი ბერიძე",
      pos: "Founder & CEO",
    },
    {
      id: 13,
      link: chiqo,
      name: "გიორგი ჩიქოვანი",
      pos: "Tech Lead",
    },
    {
      id: 14,
      link: jaba,
      name: "ჯამბულ ხაჩიძე",
      pos: "Head of Front-End Department",
    },
    {
      id: 15,
      link: mee,
      name: "ნიკოლოზ ანაშვილი",
      pos: "Senior Front-End Developer",
    },
    {
      id: 16,
      link: dato,
      name: "დავით ქარელი",
      pos: "Senior Back-End Developer",
    },
    {
      id: 17,
      link: beqa,
      name: "ბექა ბერიძე",
      pos: "Senior Back-End Developer",
    },
    {
      id: 18,
      link: silaga,
      name: "გიორგი სილაგაძე",
      pos: "Senior Front-End Developer",
    },
    {
      id: 19,
      link: teo,
      name: "თეონა ჭიღლაძე",
      pos: "Head of Marketing Department",
    },
    {
      id: 20,
      link: lana,
      name: "ლანა კიკნაძე",
      pos: "Project Manager",
    },
    {
      id: 21,
      link: rexv,
      name: "ნიკოლოზ რეხვიაშვილი",
      pos: "Grow Manager",
    },
    {
      id: 22,
      link: mariam,
      name: "მარიამ სვანიძე",
      pos: "UX/UI Designer",
    },
  ]);

  return (
    <div className="w-full flex justify-center myownbackgr">
      <div className="w-full max-w-[1519px] px-[10%]  py-[50px] max-md:py-10 flex flex-col gap-y-[30px]">
        <h1 className="text-white text-center text-[30px]">
          ჩვენი პროფესიონალი გუნდი
        </h1>
        <div className="w-full flex flex-wrap justify-between">
          <Swiper
            modules={[Autoplay, EffectFade, Pagination]}
            slidesPerView={slideToDisplay}
            spaceBetween={20}
            pagination={{ clickable: true }}
            loop={true}
            className="w-full"
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            disabledclass={"disabled_swiper_button"}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={600}
            cssease={"linear"}
          >
            <SlideFromBottom style={"w-full"}>
              {info.map((item) => (
                <SwiperSlide key={item.id}>
                  <TeamMates link={item.link} name={item.name} pos={item.pos} />
                </SwiperSlide>
              ))}
            </SlideFromBottom>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
