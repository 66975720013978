import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import googleicn from "../../imgs/1234.webp";
function SignIn({ setActive }) {
  const [isChecked, setIsChecked] = useState(false);

  const handlechecked = () => {
    setIsChecked((pre) => !pre);
  };

  const [show, setshow] = useState(false);

  const handleshow = () => {
    setshow((pre) => !pre);
  };
  return (
    <div className="w-[100%] flex flex-col gap-y-[20px]">
      <div className="flex flex-col gap-y-[10px]">
        <p className="text-[#010125]">სახელი ან ელ-ფოსტა</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="text-[#010125]">პაროლი</p>
        <div className="flex w-[100%] h-[50px] border-[1px] rounded-[10px] items-center pr-[10px] gap-[10px]">
          <input
            type={show ? "text" : "password"}
            name="password"
            id="password"
            placeholder="***********"
            className="w-[100%] h-[100%] shadow-none px-[10px] text-[#010125] outline-none rounded-[10px] appearance-none"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer " />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="saveforgot flex justify-between items-center">
        <div
          className="flex gap-[5px] items-center cursor-pointer"
          onClick={handlechecked}
        >
          <input
            type="checkbox"
            className="w-[15px] h-[15px] accent-[#004db5] cursor-pointer"
            checked={isChecked}
          />
          <p className="text-[#010125]">დამახსოვრება</p>
        </div>
        <p
          className="hover:underline cursor-pointer text-[#010125]"
          onClick={() => {
            setActive("forgetpassword");
          }}
        >
          დაგავიწყდა პაროლი?
        </p>
      </div>
      <div className="flex flex-col gap-[5px]">
        <div className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-gradient-to-r from-[#85276a] to-[#013057] hover:from-white  text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#15154c] border-[2px] border-[#4d1dc1] dark:bg-white duration-200">
          <h1>ავტორიზაცია</h1>
        </div>
        <p className="text-[#010125] text-center">ან</p>
        <div className="flex gap-[10px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-gradient-to-r from-[#85276a] to-[#013057] hover:from-white  text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#15154c] border-[2px] border-[#4d1dc1] dark:bg-white duration-200">
          <img src={googleicn} className="h-[30px]" alt="" />
          <h1>ავტორიზაცია</h1>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
