import ContactField from "../contact/contactfield";
import ContactPart from "../contact/contactspart";
import SlideFromBottom from "../animations/SlideFromBottom";

function Contact() {
  return (
    <div className="mt-[70px] w-[100%] flex items-center flex-col">
      <div
        className="contactpage max-w-[1519px] px-[10%] max-lg:p-10 max-md:p-[6%] w-[100%] pt-[50px] pb-[30px] flex flex-col items-center 
       gap-[10px] dark:bg-[#010125] duration-200 gap-y-[30px]"
      >
        <SlideFromBottom>
          <h1
            className="text-[#010125] w-full text-center py-[10px] text-[32px] dark:text-white duration-200 
            max-md:text-2xl max-sm:text-xl mb-10"
          >
            დაგვიკავშირდით
          </h1>
        </SlideFromBottom>
        <SlideFromBottom style={"w-full"}>
          <ContactPart />
        </SlideFromBottom>
        <SlideFromBottom style={"w-full"}>
          <ContactField />
        </SlideFromBottom>
      </div>
    </div>
  );
}

export default Contact;
