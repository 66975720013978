import { useEffect, useRef, useState } from "react";
import { CaretDown, Eye, EyeSlash, Google, X } from "react-bootstrap-icons";
import googleicn from "../../imgs/1234.webp";
function NewAccount() {
  const outdropedRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (outdropedRef.current && !outdropedRef.current.contains(event.target)) {
      setDroped(false);
    }
  }

  const [clicked, setClicked] = useState("ვინაობა");

  const [droped, setDroped] = useState(false);
  const handleDroped = () => {
    setDroped((pre) => !pre);
  };

  const [show, setshow] = useState(false);

  const handleshow = () => {
    setshow((pre) => !pre);
  };
  return (
    <div className="flex flex-col gap-y-[20px]">
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">სახელი</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ელ-ფოსტა</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <p className="text-[#010125] text-center">ან</p>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ტელეფონი</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">კოდი</p>
        <input
          type="text"
          className="w-[100%] h-[50px] text-[#010125] px-[10px] border-[1px] rounded-[10px] outline-none"
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">პაროლი</p>
        <div className="flex w-[100%] h-[50px] border-[1px] rounded-[10px] items-center pr-[10px] gap-[10px]">
          <input
            type={show ? "text" : "password"}
            name="password"
            id="password"
            placeholder="***********"
            className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer" />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">გაიმეორე პაროლი</p>
        <div className="flex w-[100%] h-[50px] border-[1px] rounded-[10px] items-center pr-[10px] gap-[10px]">
          <input
            type={show ? "text" : "password"}
            name="password"
            id="password"
            placeholder="***********"
            className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer " />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      <div className="w-[100%] flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ვინაობა</p>
        <div ref={outdropedRef} className="relative">
          <div
            className={`p-[15px] flex justify-between items-center cursor-pointer border-[1px] rounded-[13px] duration-150 ${
              droped ? "border-black" : ""
            }`}
            onClick={handleDroped}
          >
            <div className="flex justify-between items-center w-[100%]">
              <p className="text-[#010125]">{clicked}</p>
              <X
                className={`text-[red] ${
                  clicked != "ვინაობა" ? "flex" : "hidden"
                }`}
                onClick={() => {
                  setClicked("ვინაობა");
                }}
              />
            </div>
            <CaretDown
              className={`duration-200 text-[#010125] ${
                droped ? "rotate-180" : ""
              }`}
            />
          </div>

          <div
            className={`filterScrlbar myshadow absolute z-[5] bottom-[58px] w-[100%] overflow-auto bg-white rounded-t-[11px] ${
              droped ? "flex" : "hidden"
            }`}
          >
            <ul className="w-[100%]">
              <li
                className="px-[15px] py-[5px] text-[#010125] hover:bg-[#dbdbff] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("ვინაობა");
                }}
              >
                ვინაობა
              </li>
              <li
                className="px-[15px] py-[5px] text-[#010125] hover:bg-[#dbdbff] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("ფიზიკური პირი");
                }}
              >
                ფიზიკური პირი
              </li>
              <li
                className="px-[15px] py-[5px] text-[#010125] hover:bg-[#dbdbff] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("იურიდიული პირი");
                }}
              >
                იურიდიული პირი
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[5px]">
        <div className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-gradient-to-r from-[#85276a] to-[#013057] hover:from-white  text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#15154c] border-[2px] border-[#4d1dc1] dark:bg-white duration-200">
          <h1>რეგისტრაცია</h1>
        </div>
        <p className="text-[#010125] text-center">ან</p>
        <div className="flex gap-[10px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-gradient-to-r from-[#85276a] to-[#013057] hover:from-white  text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#15154c] border-[2px] border-[#4d1dc1] dark:bg-white duration-200">
          <img src={googleicn} className="h-[30px]" alt="" />
          <h1>ავტორიზაცია</h1>
        </div>
      </div>
    </div>
  );
}
export default NewAccount;
