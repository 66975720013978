import { useState, useEffect } from "react";
import FifthCard from "../mainpage/fifthcard";
import FifthCardReverse from "../servicespage/fifthcardreverse";
import SlideFromBottom from "../animations/SlideFromBottom";
import SlideFromLeft from "../animations/SlideFromLeft";
import SlideFromRight from "../animations/SlideFromRight";

import One from "../LottieAnimations/YxjRxdEhTR.json";
import Two from "../LottieAnimations/XONe28Kfy4.json";
import Three from "../LottieAnimations/zijOAXKzSG.json";
import Four from "../LottieAnimations/7cZsk0nn0U.json";
import Five from "../LottieAnimations/jVx9YWCymg.json";

import ContactService from "../servicespage/contactinservice";
import useScreenSize from "../customHooks/useScreenSize";

function Services({goToTopFast}) {

  const screenSize = useScreenSize();

  const [imageDimensionsone, setImageDimensionsone] = useState({
    x: 520,
    y: 350,
  });

  const [imageDimensionsthird, setImageDimensionsthird] = useState({
    x: 450,
    y: 400,
  });

  const [imageDimensionsfour, setImageDimensionsfour] = useState({
    x: 450,
    y: 450,
  });

  useEffect(() => {
    if (screenSize >= 1024 && screenSize <= 1185) {
      setImageDimensionsone({
        x: 450,
        y: 300,
      })
      setImageDimensionsthird({
        x: 300,
        y: 300,
      });
      setImageDimensionsfour({
        x: 380,
        y: 380,
      })
    } else if (screenSize >= 600 && screenSize <= 1024) {
      setImageDimensionsone({
        y: 250,
        x: 400,
      });
      setImageDimensionsfour({
        x: 320,
        y: 320,
      })
    } else if (screenSize >= 390 && screenSize <= 600) {
      setImageDimensionsone({
        y: 200,
        x: 300,
      });
      setImageDimensionsthird({
        x: 230,
        y: 210,
      });
      setImageDimensionsfour({
        x: 220,
        y: 220,
      })
    } else if (screenSize >= 0 && screenSize <= 390) {
      setImageDimensionsthird({
        x: 190,
        y: 170,
      });
      setImageDimensionsone({
        x: 270,
        y: 180,
      });
      setImageDimensionsfour({
        x: 270,
        y: 300,
      });
    } 
  }, [screenSize]);

  return (
    <div className="servmainpage mt-[70px] flex flex-col gap-y-[100px] dark:bg-[#010125] duration-200 items-center py-[50px] max-md:py-10 
    max-md:overflow-hidden">
      <div className="servicepage w-[100%] max-w-[1519px] gap-y-20 max-md:gap-y-10 flex flex-col px-10 lg:px-[10%] ">
      <SlideFromBottom>
      <h1
            className="text-[#010125] w-full text-center py-[10px] text-[32px] dark:text-white duration-200 
            max-md:text-2xl max-sm:text-xl"
          >
            სერვისები
          </h1>
          </SlideFromBottom>
        <div className="flex flex-wrap justify-between gap-[40px] gap-y-[20px] w-[100%] oveflow-hidden">
          <SlideFromLeft duration={800}>
            <FifthCard
              serv={true}
              he={imageDimensionsone.y}
              wi={imageDimensionsone.x}
              anim={Two}
              title={"ექსკლუზიური დიზაინი"}
              text={
                "თუ გსურთ თქვენ ონლაინ მაღაზიის პლატფორმას ქონდეს განსხვავებული და გამორჩეული დიზაინი, BMSOFT დაგეხმარებათ ექსკლუზიური დიზაინის შექმნაში რაღათქმაუნდა თქვენი იდეების გათვალისწონებით."
              }
              goToTopFast={goToTopFast}
              link={"/exclusive-design-systems"}
            />
          </SlideFromLeft>
          <SlideFromRight duration={800}>
            <FifthCardReverse
              anim={One}
              he={imageDimensionsthird.y}
              wi={imageDimensionsthird.x}
              title={"მობილური აპლიკაციის დამზადება"}
              text={
                "BMSOFT პლატფორმის გარდა, ჩვენ გთავაზობთ მობილური აპლიკაციის დამზადებას თქვენი საიტისთვის, რომელიც დაგეხმარებათ თავი გააცნოთ უფრო ფართო აუდიტორიას."
              }
              goToTopFast={goToTopFast}
              link={"/mobile-applications-systems"}
            />
          </SlideFromRight>
          <SlideFromLeft duration={800}>
            <FifthCard
              serv={true}
              he={imageDimensionsthird.y}
              wi={imageDimensionsthird.x}
              anim={Three}
              title={"ინტეგრაცია სააღრიცხვო პროგრამასთან"}
              text={
                "ჩვენ გვესმის, ფინანსების მართვა რთული ამოცანაა, BMSOFT - ის პლატფორმა ინტეგრირებულია სააღრიცხვო პროგრამა Optimo - თან. Optimo - სთან ინტეგრაციით, მარტივად შეძლებთ აკონტროლოთ გაყიდვები, აღრიცხოთ მარაგები და თვალი ადევნოთ შემოსავლებს."
              }
              goToTopFast={goToTopFast}
              link={"/counting-systems"}
            />
          </SlideFromLeft>
          <SlideFromRight duration={800}>
            <FifthCardReverse
              anim={Four}
              he={imageDimensionsfour.y}
              wi={imageDimensionsfour.x}
              title={"გადახდის სისტემების ინტეგრაცია"}
              text={
                "BMSOFT პლატფორმა გთავაზობთ ელ-კომერციისთვის ძალიან მნიშვნელოვანი საკითხის გადაჭრას, გადახდის სისტემებთან ინტეგრაციის საშუალებით. ეს უკანასკნელი გადახდის პროცესს მომხმარებლისთვის ხდის უფრო სწრაფსა და კომფორტულს"
              }
              goToTopFast={goToTopFast}
              link={"/payment-systems"}
            />
          </SlideFromRight>
          <SlideFromLeft duration={800}>
            <FifthCard
              serv={true}
              anim={Five}
              he={imageDimensionsthird.y}
              wi={imageDimensionsthird.x}
              title={"ლოიალობის სისტემები"}
              text={
                "BMSOFT - მა შეიმუშავა თქვენი ბიზნესისთვის ძლიერი ლოიალობის სისტემა, როგორც ჩვენი პლატფორმის ნაწილი, რომელიც საშუალებას გაძლევთ წაახალისოთ თქვენი მომხმარებლები"
              }
              goToTopFast={goToTopFast}
              link={"/loyalty-systems"}
            />
          </SlideFromLeft>
        </div>
          <SlideFromBottom>
            <ContactService/>
          </SlideFromBottom>
      </div>
    </div>
  );
}

export default Services;
