import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function FifthCardReverse({
  title,
  text,
  anim,
  he,
  wi,
  goToTopFast,
  link
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className=" w-[100%] flex gap-[10px] px-[10px] dark:text-gray-300 max-lg:flex-col">
      <div className="max-lg:order-2 w-[50%] max-lg:w-full flex flex-col gap-y-[30px] items-end justify-center ">
        <div className="relative flex ">
          <h1 className="text-[#010125] py-[10px] text-[30px] dark:text-white text-right duration-200 max-md:text-2xl max-sm:text-xl">
            {title}
          </h1>
        </div>
        <p className=" servcardtext text-right">{text}</p>
        <div className="flex w-[100%] justify-end">
          <Link to={link}>
            <h1
              className="gap-[3px] duration-200 w-[130px] h-[40px] rounded-[33px] text-[#010125] myownbackgrhover
               hover:text-white border-[1px] border-gray-300 dark:border-[0px] bg-white flex items-center 
             justify-center cursor-pointer text-[14px]"
              onClick={goToTopFast}
            >
              მეტის ნახვა
            </h1>
          </Link>
        </div>
      </div>
      <div className="max-lg:order-1 pointer-events-none w-[50%] max-lg:w-full h-[500px] rounded-[10px]  flex justify-center 
      items-center py-[10px]  max-lg:h-[300px] max-sm:h-auto ">
        <Lottie options={defaultOptions} height={he} width={wi} />
      </div>
    </div>
  );
}
