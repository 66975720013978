import { createContext, useState } from "react";

const PrimaryContext = createContext(null);

function MainContext({ children }) {
  //dark mode value
  const [darkModeValue, setDarkModeValue] = useState('light');

  return (
    <PrimaryContext.Provider
      value={{
        setDarkModeValue,
        darkModeValue,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
}

export { MainContext, PrimaryContext };
