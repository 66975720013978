import InServCard from "../servicespage/innerservices/inservcard";
import pht from "../../imgs/100.webp";
import pht2 from "../../imgs/200.webp";
import Waves from "../animations/Waves";
import SlideFromBottom from "../animations/SlideFromBottom";
import ContactService from "../servicespage/contactinservice";

export default function InnerServiceone() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <div className="fixed w-[100%] h-[100%] top-0 left-0 z-[-20]">
        <Waves />
      </div>
      <div className="w-full mt-[70px] max-w-[1519px] px-[10%] flex flex-col py-20 max-sm:py-10 gap-y-20 max-sm:gap-y-10">
        <h1
          className="text-center text-[32px] text-[#010125]
                max-md:text-2xl max-sm:text-xl"
        >
          ექსკლუზიური დიზაინი
        </h1>
        <InServCard
          foto={pht}
          text={
            "BMSOFT - ში  გვესმის რა მნიშვნელოვანია ონლაინ მაღაზიითვის უნიკალური და გამორჩეული დიზაინი. ჩვენ გვჯერა, რომ თქვენი ბრენდი იმსახურებს იყოს გამორჩეული და შეეძლოს გრძელვადიანი შთაბეჭდილების მოხდენა მომხმარებლებზე, სწორედ ამიტომ, ჩვენ გთავაზობთ ექსკლუზიურ დიზაინის მომსახურებას, რომელიც მორგებულია თქვენს კონკრეტულ საჭიროებებსა და იდეებზე."
          }
          texttwo={
            "ჩვენი გამოცდილი დიზაინერების  გუნდი მჭიდროდ იმუშავებს თქვენთან, რათა გააცოცხლოს თქვენი ხედვა. ჩვენ დავხარჯავთ დროს თქვენი ბრენდის იდენტურობის, სამიზნე აუდიტორიისა და საერთო მიზნების გასაგებად, რომელიც შემდგომ უზრუნველყოფს, რომ დიზაინი იდეალურად შეესაბამებოდეს თქვენი ბიზნესის მიზნებს."
          }
          step={"ექსკლუზიური დიზაინი თქვენი ონლაინ მაღაზიისთვის"}
        />
        <InServCard
          rev={true}
          foto={pht2}
          text={
            "დიზაინის აწყობა მიმდინარეობს ციფრული ინდუსტრიის ერთ-ერთ წამყვან Figma პროგრამაში რომელიც საშუალებას გვაძლევს შევქმნათ მაღალი ხარისხის მარკეტები და პროტოტიპები."
          }
          texttwo={"ჩვენთვის ძალიან მნიშვნელოვანია და ვაფასებთ თქვენს ნებისმიერ შენიშვნას და გამოხმაურებას ექსკლუზიური დიზაინის შექმნის პროცესში, ის გვეხმარება დიზაინის სრულყოფილებამდე დახვეწაში. დიზაინის  შემდგომი დეველოპმენტი ხდებაა JavaScript  -  ის ბიბლიოთეკაში, ექსკლუზიური დიზაინის კომპონენტების შესაქმნელად. ეს საშუალებას გვაძლევს შევქმნათ დინამიური და ინტერაქტიული ელემენტები, რომლებიც გაზრდის მომხმარებლის გამოცდილებას და გახდის თქვენს ონლაინ მაღაზიას მიმზიდველს."}
          step={
            "ჩვენ აქ ვართ რომ თქვენი იდეები ვიზუალურად განსაცვიფრებელ რეალობად ვაქციოთ"
          }
        />
        <InServCard
          foto={pht}
          text={
            "თვალისმომჭრელი ანიმაციებიდან დაწყებული ინტუიციურ ნავიგაციამდე, ყველა დეტალი საგულდაგულოდ იქნება შემუშავებული, რათა მოხიბლოთ თქვენი მომხმარებლი და გაუჩინოთ ნივთის ან მომსახურების შეძენის სურვილი."
          }
          texttwo={
            "ექსკლუზიური დიზაინის სერვისის შეძენით თქვენ განასხვავებთ თქვენს ონლაინ მაღაზიას კონკურენტებისგან და მოახდენთ ხანგრძლივ შთაბეჭდილებას თქვენს ვიზიტორებზე. ვიზუალურად მიმზიდველი, მოსახერხებელი დიზაინი გააძლიერებს თქვენი ბრენდის იმიჯს, გააუმჯობესებს მომხმარებლის კმაყოფილების დონეს და ხელს შეუწყობს განმეორებით შესყიდვებს."
          }
          step={
            "თვალისმომჭრელი ანიმაციებიდან დაწყებული - ინტუიციურ ნავიგაციამდე"
          }
        />
        <SlideFromBottom>
          <ContactService />
        </SlideFromBottom>
      </div>
    </div>
  );
}
