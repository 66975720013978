import { useSpring, animated } from "react-spring";
import { useRef } from "react";
import useIntersectionObserver from "../customHooks/IntObserver";

const SlideFromBottom = ({ children , style}) => {
  //object ref
  const wholeDivRef = useRef(null);
  //ref intersection observer
  const dataRef = useIntersectionObserver(wholeDivRef, {
    freezeOnceVisible: true,
  });

  //animation
  const props = useSpring({
    config: { duration: 300 },
    from: {
      opacity: 0,
      y: 20,
    },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      y: dataRef?.isIntersecting ? 0 : 20,
    },
  });

  return (
    <animated.div style={props} className={style}>
      <div ref={wholeDivRef} className="w-full h-full">{children}</div>
    </animated.div>
  );
};

export default SlideFromBottom;
