import img from '../../imgs/7.webp';

function HostingCard({
}) {
  return (
    <div className={"w-full rounded-lg border border-gray-200 shadow-md bg-white dark:bg-[#010120]"}>
        <div className={"flex flex-col items-center justify-center gap-2 p-5"}>
            <div className={"w-20 h-20 p-1 shadow-md border border-gray-200 bg-white rounded-full"}>
                <img className="w-full h-full rounded-full border border-gray-200 shadow-bs" src={img}/>
            </div>
            <p className={`text-lg font-semibold text-center dark:text-white`}>.ჭყ</p>
            <p className='text-sm font-semibold text-gray-500 text-center dark:text-white'>მაგარია დომენი, დომენია მაგარი</p>
        </div>
        <div className={`flex flex-col items-center justify-center gap-2 p-5 bg-gradient-to-r from-[#85276a] to-[#013057] rounded-b-lg`}>
            <p className={`text-md font-semibold text-white text-center`}>იყიდე სიფთად</p>
            <p className='text-sm font-semibold text-white text-center'>$ 80 /წამში</p>
        </div>
    </div>
  );
}

export default HostingCard;
