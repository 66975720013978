import { useState } from "react";
import BlockBlog from "./blockblog";
import ftoo from "../../imgs/office4.jpg";
import SlideFromBottom from "../animations/SlideFromBottom";

function BlogStart() {
  const [dataSource, setDataSource] = useState(Array.from({ length: 1 }));

  const fetchMoreData = () => {
    setTimeout(() => {
      setDataSource(dataSource.concat(Array.from({ length: 1 })));
    }, 500);
  };

  const [data, setData] = useState([
    {
      id: 1,
      blogimg: ftoo ,
      blogtitle:
        "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "56:24",
    },
    {
      id: 2,
      blogimg: ftoo ,
      blogtitle:
        "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
      blogtext:
        "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
      blogtime: "01:50",
    },
    {
      id: 3,
      blogimg: ftoo,
      blogtitle: "„ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "52:00",
    },
    {
      id: 4,
      blogimg: ftoo,
      blogtitle:
        "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "56:24",
    },
    {
      id: 5,
      blogimg: ftoo,
      blogtitle:
        "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
      blogtext:
        "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
      blogtime: "01:50",
    },
    {
      id: 6,
      blogimg: ftoo,
      blogtitle: "„ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "52:00",
    },
    {
      id: 7,
      blogimg: ftoo,
      blogtitle:
        "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "56:24",
    },
    {
      id: 8,
      blogimg: ftoo,
      blogtitle:
        "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
      blogtext:
        "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
      blogtime: "01:50",
    },
    {
      id: 9,
      blogimg: ftoo,
      blogtitle: "„ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "52:00",
    },
    {
      id: 10,
      blogimg: ftoo,
      blogtitle:
        "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "56:24",
    },
    {
      id: 11,
      blogimg: ftoo,
      blogtitle:
        "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
      blogtext:
        "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
      blogtime: "01:50",
    },
    {
      id: 12,
      blogimg: ftoo,
      blogtitle: "„ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "52:00",
    },
    {
      id: 13,
      blogimg: ftoo,
      blogtitle:
        "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "56:24",
    },
    {
      id: 14,
      blogimg: ftoo,
      blogtitle:
        "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
      blogtext:
        "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
      blogtime: "01:50",
    },
    {
      id: 15,
      blogimg: ftoo,
      blogtitle: "„ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
      blogtext:
        "საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი საბედისწერო შეხვედრების, მოულოდნელი",
      blogtime: "52:00",
    },
  ]);

  return (
    <div className="w-full">
      <div className="flex items-center w-full py-5">
        <SlideFromBottom>
          <div
            className="blogmaintitle bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057]
        w-[300px] h-[40px] pt-[4px] items-center flex justify-center text-white rounded-[33px] text-[20px]"
          >
            <h1>ბლოგები</h1>
          </div>
        </SlideFromBottom>
      </div>

      <div className="w-[100%]">
        <div className="w-full grid grid-cols-3 gap-10 max-lg:gap-5 max-lg:grid-cols-2 max-sm:grid-cols-1">
          {data.map((item) => (

            <SlideFromBottom style={"w-full"}>
            <BlockBlog
              blogimg={item.blogimg}
              blogtitle={item.blogtitle}
              blogtext={item.blogtext}
              blogtime={item.blogtime}
            />
          </SlideFromBottom>
          ))}
          
        </div>
        <div className="showmoreblog flex justify-center mb-[30px] mt-[40px]">
          <SlideFromBottom>
            <h1
              className="bg-gradient-to-r from-[#85276a] via-[#4d1dc1] to-[#013057] hover:shadow-lg dark:shadow-white duration-200 cursor-pointer w-[140px] pt-[9px] pb-[7px] rounded-[33px] text-white text-center"
              onClick={fetchMoreData}
            >
              მეტის ნახვა
            </h1>
          </SlideFromBottom>
        </div>
      </div>
    </div>
  );
}

export default BlogStart;
