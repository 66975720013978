import SlideFromBottom from "../animations/SlideFromBottom";
import TiltEffect from "../animations/TiltEffect";
import Lottie from "react-lottie";
import One from "../LottieAnimations/qTHpnvGNDr.json";
import { useEffect, useState } from "react";
import useScreenSize from "../customHooks/useScreenSize";

export default function AboutTop() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: One,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const screenSize = useScreenSize();

  const [imageDimensions, setImageDimensions] = useState({
    x: 500,
    y: 500,
  });

  useEffect(() => {
    if (screenSize >= 500 && screenSize <= 650) {
      setImageDimensions({
        y: 400,
        x: 400,
      });
    } else if (screenSize >= 0 && screenSize <= 500) {
      setImageDimensions({
        y: 250,
        x: 250,
      });
    }
  }, [screenSize]);
  // end screen width
  return (
    <div className="w-full flex items-center max-lg:flex-col-reverse max-lg:gap-y-5">
      <div className="flex flex-col w-[50%] gap-y-[10px] pb-[20px] max-lg:w-[100%]">
        <SlideFromBottom style={""}>
          <h1 className="font-semibold text-[33px] max-xl:text-[25px] max-md:text-2xl max-sm:text-xl dark:text-white">
            „ARTSPACE SOFTWARE“ 2018 წლიდან ციფრულ ინდუსტრიაში
          </h1>
        </SlideFromBottom>
        <SlideFromBottom style={""}>
          <p className="text-gray-600">
            კომპანია „Artspace software “ 2018 წლიდან ქმნის ციფრულ პროდუქტებს
            იგი აქტიურად მოღვაწეობს ქართულ და უცხოურ ციფრულ ბაზარზე და
            თანამშრომლობს უამრავ წამყვან კომპანიასთან. კომპანია მომხმარებლებს
            სთავაზობს მაღალი ხარისხის მომსახურებას და ციფრული პროდუქტების ფართო
            არჩევანს. თითოეული პროდუქტის წარმატებას განაპირობებს თანამშრომლთა
            პროფესიონალიზმი, გამოცდილება, კრეატიულობა და უახლესი
            მიდგომები, რომელსაც ისინი დამკვეთის პროექტში იყენებენ.
          </p>
        </SlideFromBottom>
      </div>
      <div
        className="w-[50%] max-md:w-10/12 h-[500px] max-lg:h-auto max-lg:w-full
      oveflow-hidden pointer-events-none"
      >
        <SlideFromBottom style={""}>
          <TiltEffect mstyle={`w-full h-full`}>
            <Lottie
              options={defaultOptions}
              height={imageDimensions.y}
              width={imageDimensions.x}
            />
          </TiltEffect>
        </SlideFromBottom>
      </div>
    </div>
  );
}
