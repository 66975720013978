import { List, Person } from "react-bootstrap-icons";

import sitelogo from "../imgs/400.webp";
import logodark from "../imgs/300.webp";

import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import DropDownMenu from "./dropmenu";
import LoginRegister from "./popups/loginregister";
import { PrimaryContext } from "./MainContext/MainContext";

function Header({ dark, goToTopFast, goToTopSmooth }) {
  //dark mode value
  const { darkModeValue } = useContext(PrimaryContext);
  const [dropmenu, setDropmenu] = useState(false);
  const [logreg, setLogreg] = useState(false);

  const handleDropmenu = () => {
    setDropmenu((pre) => !pre);
  };
  const handleLogreg = () => {
    setLogreg((pre) => !pre);
  };

  useEffect(() => {
    if (logreg) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [logreg]);
  
  return (
    <div className="w-[100%] z-[10] flex justify-center fixed top-0 bg-white border-b-[1px] duration-200 dark:border-gray-800 dark:bg-[#03031d] dark:text-gray-200">
      <div className="headerdiv max-w-[1519px] w-[100%] h-[70px] px-[4%] flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/">
            <div
              className="siteLogo h-[55px] py-[5px] cursor-pointer max-sm:h-[40px]"
              onClick={goToTopSmooth}
            >
              <img
                src={darkModeValue !== "dark" ? sitelogo : logodark}
                alt="#"
                className="w-[100%] h-[100%] object-contain pr-[10px] duration-150"
              />
            </div>
          </Link>
        </div>
        <div className="headerList h-[100%]">
          <ul className="headListStyle flex items-baseline pt-[4px] gap-[20px] h-[100%]  text-[15px]">
            <li className="cursor-pointer duration-200 " onClick={goToTopFast}>
              <Link to="/">
                <p className="hmli pb-[2px] text-[#010125] dark:text-white">
                  მთავარი
                </p>
              </Link>
            </li>
            <li
              className="flex items-center gap-[5px] relative group h-[100%] duration-200"
              onClick={goToTopFast}
            >
              <Link to="pricing">
                <p className="hmli text-[#010125] dark:text-white">პროდუქტი</p>
              </Link>
            </li>
            <li
              className="flex items-center gap-[5px] relative group h-[100%] duration-200"
              onClick={goToTopFast}
            >
              <Link to="services">
                <p className="hmli text-[#010125] dark:text-white">სერვისები</p>
              </Link>
            </li>
            <li className="cursor-pointer duration-200" onClick={goToTopFast}>
              <Link to="aboutus">
                <p className="hmli text-[#010125] dark:text-white">
                ჩვენ შესახებ
                </p>
              </Link>
            </li>
            <li className="cursor-pointer duration-200" onClick={goToTopFast}>
              <Link to="contact">
                <p className="hmli text-[#010125] dark:text-white">კონტაქტი</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="headRightBtns flex items-center gap-[10px] text-[12px]">
          <div
            className="headerAuto gap-[3px] duration-200 w-[130px] h-[35px] rounded-[33px] text-[#010125] myownbackgrhover hover:text-white border-[1px] border-gray-300 dark:border-[0px] bg-white flex items-center justify-center cursor-pointer"
            onClick={handleLogreg}
          >
            <Person className="text-[18px] pb-[1px]" />
            <h1 className="pt-[2px]">ავტორიზაცია</h1>
          </div>
          <Link to="instructions">
            <div
              className="headerInstr w-[120px] pt-[2px] duration-150 h-[35px] rounded-[33px] flex items-center justify-center cursor-pointer myownbackgr text-white border-[1px] border-gray-300 dark:border-0"
              onClick={goToTopFast}
            >
              <h1>ინსტრუქციები</h1>
            </div>
          </Link>
          <div
            className="menuicon w-[35px] h-[35px] hidden justify-center items-center myownbackgr text-white rounded-full text-[20px] cursor-pointer"
            onClick={handleDropmenu}
          >
            <List />
          </div>
        </div>
      </div>
        <DropDownMenu
          handleDropmenu={handleDropmenu}
          setDropmenu={setDropmenu}
          goToTopFast={goToTopFast}
          dropmenu={dropmenu}
        />
      {logreg && (
        <LoginRegister setLogreg={setLogreg} handleLogreg={handleLogreg} />
      )}
    </div>
  );
}

export default Header;
