import useScreenSize from "../customHooks/useScreenSize";

const InstructionsCard = ({ link }) => {
  const screenSize = useScreenSize();

  return (
    <div className="flex gap-2  max-lg:gap-5 max-lg:flex-col max-sm:flex-row">
      <video width={screenSize > 1024 ? "150" : "100%"} height="150" className="rounded-lg max-sm:w-[50%] max-extrasm:w-[45%]">
        <source src={link} type="video/mp4" />
      </video>
      <div className="w-full flex flex-col gap-[8px] max-extrasm:gap-[2px]">
        <h1 className="text-lg dark:text-white max-extrasm:text-base">
          კატეგორიების დამატება
        </h1>
        <p className="text-md text-gray-500 text-[13px] blogblocktitle">
          ვიდეოში საკმაოდ დეტალურად არის აღწერილი იმ აქტივობების ჩამონათვალი, რომლებიც აუცილებელია საკუთარი ონლაინ მაღაზიის გასახსნელად.
        </p>
      </div>
    </div>
  );
};

export default InstructionsCard;
