import SlideFromBottom from "../animations/SlideFromBottom";

function TeamMates({ name, pos, link }) {
  return (
    <SlideFromBottom style={"w-full"}>
      <div className=" w-full p-[20px] flex flex-col h-[230px] items-center gap-y-3 rounded-[10px] bg-white hover:shadow-lg dark:shadow-white duration-150">
        <div className="w-[100px] h-[100px] rounded-full big-gray-shadow">
          <img
            src={link}
            className="w-[100%] h-[100%] object-cover rounded-full"
          />
        </div>
        <h1 className="text-[14px] text-[#010125] text-center">{name}</h1>
        <p className="text-gray-500 text-[12px] text-center">{pos}</p>
      </div>
    </SlideFromBottom>
  );
}

export default TeamMates;
