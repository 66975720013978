import InServCard from "../servicespage/innerservices/inservcard";
import pht from "../../imgs/100.webp";
import pht2 from "../../imgs/200.webp";
import Waves from "../animations/Waves";
import SlideFromBottom from "../animations/SlideFromBottom";
import ContactService from "../servicespage/contactinservice";

export default function InnerServicethree() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <div className="fixed w-[100%] h-[100%] top-0 left-0 z-[-20]">
        <Waves />
      </div>
      <div className="w-full mt-[70px] max-w-[1519px] px-[10%] flex flex-col py-20 max-sm:py-10 gap-y-20 max-sm:gap-y-10">
        <h1
          className="text-center text-[35px] text-[#010125]
                max-md:text-2xl max-sm:text-xl"
        >
          ინტეგრაცია სააღრიცხვო პროგრამასთან
        </h1>
        <InServCard
          foto={pht}
          text={
            "BMSOFT - ის პლატფორმა, ინტეგრირებულია სააღრიცხვო პროგრამა Optimo - სთან და ამარტივებს ფინანსების მართვის რთულ ამოცანას."
          }
          texttwo={
            "Optimo - ს საშუალებით თქვენ შეგიძლიათ უპრობლემოდ ადევნოთ თვალყური შემოსავალს, ხარჯებს და ფულადი სახსრების მოძრაობას, იგი უზრუნველყოფს ზუსტ და განახლებულ ფინანსურ ჩანაწერებს."
          }
          textthree={
            "პლატფორმა ავტომატიზირებს ბევრ სააღრიცხვო პროცესს, ამცირებს ადამიანური შეცდომის რისკს და ზოგავს თქვენს ძვირფას დროს. ინვოისების გენერირებიდან ფინანსურ ანგარიშგებამდე."
          }
          step={
            "ბუღალტერია საბუღალტრო პროგრამული უზრუნველყოფით თქვენს ვებგვერდზე"
          }
        />
        <InServCard
          rev={true}
          foto={pht2}
          text={
            "Optimo აუმჯობესებს ბუღალტრული აღრიცხვის მთელ პროცესს, რაც საშუალებას გაძლევთ ფოკუსირება მოახდინოთ თქვენი ბიზნესის განვითარებაზე."
          }
          texttwo={
            "თქვენ არ გჭირდებათ დამატებითი დროისა და ძალისხმევის დახარჯვა სხვადასხვა აპლიკაციებს შორის გადართვის ან მონაცემთა სინქრონიზაციის საკითხების გადასაჭრელად."
          }
          textthree={
            "Optimo-ს მოსახერხებელი ინტერფეისი და ინტუიციური ფუნქციები მას ხელმისაწვდომს ხდის ნებისმიერი ადამიანისთვის, მაშინაც კი, თუ ბუღალტრული აღრიცხვის ექსპერტი არ ხართ, შეგიძლიათ მარტივად მიიღოთ მნიშვნელოვანი ინფორმაცია თქვენი ბიზნესის ფინანსური ეფექტურობის შესახებ და წარმართოთ თქვენი ბიზნესი წარმატებისკენ."
          }
          step={"მოახდინეთ ფოკუსირება ბიზნესის განვითარებაზე"}
        />
        <InServCard
          foto={pht}
          text={
            "BMSOFT - ის პლატფორმის არჩევით რომელიც ინტეგრირებულია Optimo - ს საბუღალტრო პროგრამასთან შეამცირებთ ადმინისტრაციულ ტვირთს და შეძლებთ უკეთ აკონტროლოთ თქვენი ფინანსური მენეჯმენტი."
          }
          texttwo={
            "ჩვენ ვცდილობთ შემოგთავაზოთ უწყვეტი და ეფექტური გამოცდილება, რაც საშუალებას მოგცემთ ფოკუსირება მოახდინოთ იმაზე, რაც ყველაზე მნიშვნელოვანია -  „თქვენი ბიზნესის ზრდა“"
          }
          step={"უკეთ აკონტროლეთ ფინანსები"}
        />
        <SlideFromBottom>
          <ContactService />
        </SlideFromBottom>
      </div>
    </div>
  );
}
