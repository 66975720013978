import Fifth from "../mainpage/fifth";
import FirstPart from "../mainpage/first";
import NewCardStyle from "../mainpage/newcardstyle";
import NoteSecur from "../mainpage/notesecur";
import Offers from "../mainpage/offers";
import Thirdpart from "../mainpage/third";
import Whatwedo from "../mainpage/fourth";

function MainPage({goToTopFast}) {
  return (
    <div className="w-[100%] items-center flex flex-col gap-y-20 dark:bg-[#010125]">
      <div className="mainpage flex flex-col w-[100%] max-w-[1519px] px-[10%] pb-20 mt-[70px] gap-y-20
      ">
        <FirstPart />
        <Thirdpart />
        <NewCardStyle />
        <Whatwedo />
        <Offers />
        <NoteSecur goToTopFast={goToTopFast}/>
        <Fifth />
      </div>
    </div>
  );
}

export default MainPage;
