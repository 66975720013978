import { Link } from "react-router-dom";
import { BsFillCalendarDateFill } from "react-icons/bs";

function BlockBlog({ blogimg, blogtitle, blogtext, blogtime }) {
  return (
    <div className="w-full flex gap-1 flex-col">
      <div className="relative blobkspht w-[100%] h-[240px] cursor-pointer rounded-bl-[55px] rounded-tr-[55px]">
        <img
          src={blogimg}
          className="bloksphtin w-[100%] h-[100%] object-cover"
          alt="#"
        />
      </div>
      <div className="padblock flex flex-col justify-between w-full gap-2">
        <Link to="/blog/inBlog">
          <p className="blogblocktitle font-semibold text-lg cursor-pointer dark:text-white">
            {blogtitle}
          </p>
        </Link>
        <div className={"flex items-center gap-2"}>
          <BsFillCalendarDateFill
            className={"w-4 h-4 text-[#013057] dark:text-white"}
          />
          <p className="duration-200 text-[#013057] text-sm dark:text-white mt-1">
            {blogtime}
          </p>
        </div>
        <p className={"text-sm text-gray-500 font-semibold blogblocktext"}>
          {blogtext}
        </p>
        <Link to="/blog/inBlog" className={"self-start"}>
          <p
            className="gap-[3px] duration-200 w-[140px] h-[40px] rounded-[33px] text-[#010125] font-bold
          hover:bg-gradient-to-r from-[#85276a] to-[#013057] hover:text-white border-[1px] 
          border-gray-300 dark:border-[0px] bg-white flex items-center justify-center cursor-pointer"
          >
            მეტის ნახვა
          </p>
        </Link>
      </div>
    </div>
  );
}
export default BlockBlog;
