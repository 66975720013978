import { useContext, useEffect, useState } from "react";
import Switch from "./Switch";
import { PrimaryContext } from "../MainContext/MainContext";

const DarkModeSwitcher = () => {
  const [theme, setTheme] = useState(null);
  const { setDarkModeValue } = useContext(PrimaryContext);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: light)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
    setDarkModeValue(theme)
  };
  return (
    <div className="">
      <Switch handleThemeSwitch={handleThemeSwitch} />
    </div>
  );
};

export default DarkModeSwitcher;
