import { useSpring, animated } from "react-spring";
import { useRef } from "react";
import useIntersectionObserver from "../customHooks/IntObserver";

const Bounce = ({ children, style, start, end }) => {
  //object ref
  const wholeDivRef = useRef(null);
  //ref intersection observer
  const dataRef = useIntersectionObserver(wholeDivRef, {
    freezeOnceVisible: true,
  });

  //animation
  const props = useSpring({
    config: { duration: 2000 },
    loop: {
      reverse: true,
    },
    from: {
      y: start,
    },
    to: {
      y: dataRef?.isIntersecting ? end : start,
    },
  });

  return (
    <animated.div style={props} className={style}>
      <div ref={wholeDivRef} className="w-full h-full">
        {children}
      </div>
    </animated.div>
  );
};

export default Bounce;
