import imgcard1 from "../../imgs/9999.webp";
import imgcard2 from "../../imgs/101010.webp";
import imgcard3 from "../../imgs/0111111.webp";
import { ArrowRight } from "react-bootstrap-icons";
import FifthCard from "./fifthcard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css/effect-fade";
import { useRef } from "react";

function Fifth() {
  const swiperRef = useRef();
  return (
    <div className="w-[100%] flex justify-center">
      <div className="portfolio w-[100%] flex flex-col gap-y-[20px]">
        <div className="portfoliotoptitlecont w-[100%] flex justify-between items-center">
          <div className="portfollefttitle flex flex-col gap-y-[10px] mb-[20px]">
            <h1 className="text-[#010125] dark:text-gray-400">პორტფოლიო</h1>
            <h1 className="portfoltitle text-[30px] myownbackgr inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200">
              ჩვენი პროდუქტები
            </h1>
          </div>
          <div className="w-[130px]">
            <div className="hover:border-b-[1px] w-[0px] cursor-pointer h-[28px] border-[#4d1dc1]  dark:border-white duration-200 hover:w-[130px]">
              <div className="flex w-[130px] items-center gap-[5px] myownbackgr  text-transparent bg-clip-text text-[18px] duration-200 dark:text-white">
                <p className="">მეტის ნახვა</p>
                <ArrowRight className="text-[#013057]  duration-200 dark:text-white" />
              </div>
            </div>
          </div>
        </div>

        <div className="portfolcards w-[100%] flex flex-wrap relative">
          <Swiper
            modules={[Autoplay, EffectFade, Pagination]}
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={20}
            pagination={{ clickable: true }}
            loop={true}
            className=""
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            disabledclass={"disabled_swiper_button"}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={1000}
            cssease={"linear"}
          >
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard1} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard2} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard3} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard1} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard2} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard3} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard1} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard2} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
            <SwiperSlide className="px-[10px]">
              <FifthCard img={imgcard3} title={"ავტომობილების იმპორტი"} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Fifth;
