import SlideFromBottom from "../../animations/SlideFromBottom";

export default function InServCard({
  rev,
  foto,
  text,
  texttwo,
  textthree,
  textfour,
  step,
}) {
  return (
    <div className={`${rev ? "flex justify-end" : ""}`}>
      <div
        className={`w-[80%] max-lg:w-full flex gap-[40px] items-center justify-center 
        max-md:flex-col
        ${rev ? "flex-row-reverse" : ""}`}
      >
        <SlideFromBottom style={"w-[45%] max-md:w-full"}>
          <div className=" w-full shadow-xl rounded-[20px] bg-white">
            <img src={foto} className="w-full object-cover rounded-[20px]" />
          </div>
        </SlideFromBottom>
        <SlideFromBottom style={"w-[60%] max-md:w-full"}>
          <div className="w-[100%] flex flex-col gap-y-[10px]">
            <h1 className="text-[20px] text-[#c75c6f] font-semibold mb-[20px]">
              {step}
            </h1>
            <p className="text-gray-500">{text}</p>
            <p className="text-gray-500">{texttwo}</p>
            <p className="text-gray-500">{textthree}</p>
            <p className="text-gray-500">{textfour}</p>
            <div
              className={
                step ===
                "აპლიკაცია კომპანიის იმიჯის გაზრდის ყველაზე ეფექტური საშალება"
                  ? "flex flex-col text-gray-500 mt-[-25px]"
                  : " hidden"
              }
            >
              <p className="">
                {" "}
                დიზაინის აწყობა მიმდინარეობს figma პროგრამაში ხოლო მისი შემდგი
                დეველოპმენტი ხდებაა ჯავა სკრიპტის ბიბლიოთეკაში. აპლიკაციების
                დამზადება ხორციელდება შემდეგ პლატფორმებზე:
              </p>
              <ul>
                <li>• Android</li>
                <li>• IOS</li>
              </ul>
              <p>მობილური აპლიკაციის საშუალებით თქვენი კომპანია შეძლებს:</p>
              <ul>
                <li>• ცნობადობის გაზრდას;</li>
                <li>• კომპანიის ბრენდის პოპულარიზაციას;</li>
                <li>• პოტენციურ კლიენტებთან დაახლოებას;</li>
                <li>• გაყიდვების ზრდას;</li>
                <li>• ფულადი სარგებლის მიღება</li>
              </ul>
            </div>
            <div
              className={
                step === "გადახდის მეთოდები BMSOFT პლატფორმაზე"
                  ? "flex flex-col text-gray-500 mt-[-25px] gap-[10px]"
                  : " hidden"
              }
            >
              <p>პლატფორმას აქვს 5 გადახდის მეთოდი:</p>
              <ul>
                <li>• გადახდა ადგილზე ნაღდი ანგარიშსწორებით</li>
                <li>• გადახდა ადგილზე ტერმინალის მეშვეობით;</li>
                <li>• გადაგხდა ონლაინ ბარათით;</li>
                <li>
                  • ანგარიშსწორება PayBox-ის აპარატით ანგარიშზე თანხის შემოტანის
                  მეშვეობით;
                </li>
                <li>
                  • თანხის ჩარიცხვა ანგარიშზე, ბანკის, ან ინტერნეტ ბანკინგის
                  მეშვეობით;
                </li>
              </ul>
              <p>
                მომხმარებლის ფინანსური ინფორმაცია დაცულია ბანკის მიერ დაშიფრული
                ტრანზაქციებისა და უსაფრთხიების თანამედროვე სტანდარტის მეშვეობით
                . არ გამოტოვოთ შესაძლებლობა გამოირჩეოდეთ თქვენი
                კონკურენტებისგან. შემოუერთდით ჩვენს პლატფორმას
              </p>
            </div>
          </div>
        </SlideFromBottom>
      </div>
    </div>
  );
}
