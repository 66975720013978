import { useState } from "react";
import NewCardOne from "./newcardone";
import SlideFromBottom from "../animations/SlideFromBottom";

export default function NewCardStyle() {
  const [month, setMonth] = useState(true);

  const handleMonth = () => {
    setMonth((pre) => !pre);
  };
  return (
    <div className="w-[100%] flex flex-col items-center">
      <SlideFromBottom>
        <div className="flex flex-col items-center gap-y-[20px]">
          <h1 className="whybmtitle text-[30px] myownbackgr inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200">
            პაკეტები
          </h1>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex justify-center py-[30px]">
          <div className="flex p-[5px] gray-shadow rounded-[33px] gap-[5px] bg-white">
            <p
              className={`px-[15px] pb-[3px] rounded-[33px] cursor-pointer duration-200 ${month ? "myownbackgr text-white" : ""
                }`}
              onClick={handleMonth}
            >
              თვე
            </p>
            <p
              className={`px-[15px] pb-[3px] rounded-[33px] cursor-pointer duration-200 ${month ? "" : "myownbackgr text-white"
                }`}
              onClick={handleMonth}
            >
              შესყიდვა
            </p>
          </div>
        </div>
      </SlideFromBottom>

      <div className="pricepackdiv grid grid-cols-3 gap-y-[20px] w-[100%] ">
        <SlideFromBottom>
          <NewCardOne
            cardtitle={"სტარტაპი"}
            cotitle={"Free 14 days trial, you can use over 20 basic features"}
            month={month}
            txt1={"5GB სივრცე"}
            txt2={"70% პერფორმანსი"}
            txt3={"უფასო ლიმიტირებული პლაგინები"}
            txt4={"SSL სერტიფიკატი"}
            txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
          />
        </SlideFromBottom>

        <SlideFromBottom>
          <NewCardOne
            cardtitle={"მედიუმი"}
            cotitle={"Free 14 days trial, you can use over 20 basic features"}
            month={month}
            txt1={"10 GB სივრცე"}
            txt2={"85% პერფორმანსი"}
            txt3={"უფასო ლიმიტირებული პლაგინები"}
            txt4={"SSL სერტიფიკატი"}
            txt6={"Rs.ge ინტეგრაცია"}
            txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
          />
        </SlideFromBottom>

        <SlideFromBottom>
          <NewCardOne
            cardtitle={"ენტერპრაისი"}
            cotitle={"Free 14 days trial, you can use over 20 basic features"}
            month={month}
            txt1={"20 GB სივრცე"}
            txt2={"99% პერფორმანსი"}
            txt3={"უფასო ლიმიტირებული პლაგინები"}
            txt4={"SSL სერტიფიკატი"}
            txt6={"Rs.ge ინტეგრაცია"}
            txt5={"მომხმარებლის შეუზღუდავი ანგარიში"}
          />
        </SlideFromBottom>
      </div>
    </div>
  );
}
