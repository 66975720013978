import { useState, useEffect } from "react";
import { SunFill, MoonStarsFill } from "react-bootstrap-icons";

const Switch = ({ handleThemeSwitch }) => {
    const [checkedValue, setCheckedValue] = useState(true);
    const handleCheckedValue = (e) => {
        setCheckedValue(e.target.checked);
    }

    useEffect(() => {
        handleThemeSwitch();
    }, [checkedValue])

    return (
        <div className="fixed top-[200px] right-[3px] z-[10]">
            <label className="relative inline-block  h-[57px] w-[30px]">
                <input
                    onChange={(e) => { handleCheckedValue(e) }}
                    type="checkbox"
                    className="opacity-0 w-0 h-0">
                </input>
                <div className="rounded-full slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-200">
                    <div className={`w-[24px] h-[24px] absolute bg-white left-[3px] top-[3px] 
                        transition-all ease-in rounded-full p-1 dark:bg-[#010120]
                        ${checkedValue ? "translate-y-[25px] " : ""}`}>
                        {checkedValue ?
                            (< SunFill className=""/>):
                            (< MoonStarsFill className="dark:text-white "/>) 
                        }
                    </div>
                </div>
            </label>
        </div>
    )
}

export default Switch;