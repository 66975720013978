import { useEffect, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";

const AccordionTwoItem = ({
  question,
  answer,
  index,
  opend,
  filterQuestions,
}) => {
  //is clicked
  const [isOpend, setIsOpend] = useState(false);

  //change clicked item
  useEffect(() => {
    setIsOpend(opend);
  }, [opend]);

  return (
    <div
      onClick={() => {
        filterQuestions(index);
      }}
      className={`cursor-pointer w-full self-start border border-gray-200 shadow-sm rounded-[10px] bg-white`}
    >
      <div
        className={`transition-all ease-linear delay-100 duration-200 flex items-center justify-between w-full p-5 
              rounded-t-[10px]  ${isOpend && ` bg-[#f8f7fd]`}`}
      >
        <h1 className="w-[95%] max-sm:text-[14px]">{question}</h1>
        <ChevronDown
          className={`transition-all ease-linear delay-100 duration-200 ${isOpend && "rotate-180"}`}
        />
      </div>
      <div
        className={`transition-all ease-linear duration-500 px-5
                ${isOpend ? "pb-[15px]" : "overflow-hidden"}`}
      >
        <p
          className={`transition-all ease-linear duration-200  
                ${
                  isOpend
                    ? "h-auto opacity-1 text-[15px] text-[gray] mt-5 "
                    : "opacity-0 h-0"
                }`}
        >
          {answer}
        </p>
      </div>
    </div>
  );
};

export default AccordionTwoItem;
