import SlideFromBottom from "../animations/SlideFromBottom";
import Lottie from "react-lottie";
import One from "../LottieAnimations/4XXGrdDHMw.json";
import { useEffect, useState } from "react";
import useScreenSize from "../customHooks/useScreenSize";

export default function SecAbout() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: One,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const screenSize = useScreenSize();

  const [imageDimensions, setImageDimensions] = useState({
    x: 500,
    y: 500,
  });

  useEffect(() => {
    if (screenSize >= 450 && screenSize <= 650) {
      setImageDimensions({
        y: 350,
        x: 280,
      });
    } else if (screenSize >= 370 && screenSize <= 450) {
      setImageDimensions({
        y: 250,
        x: 250,
      });
    } else if (screenSize >= 300 && screenSize <= 370) {
      setImageDimensions({
        y: 150,
        x: 200,
      });
    }
  }, [screenSize]);
  // end screen width
  return (
    <div className="w-full flex items-center max-lg:flex-col max-lg:gap-y-5">
      <div className="w-[50%] max-lg:w-[69%] pointer-events-none h-[600px] max-lg:h-auto">
        <SlideFromBottom style={""}>
          <Lottie
            options={defaultOptions}
            height={imageDimensions.y}
            width={imageDimensions.x}
          />
        </SlideFromBottom>
      </div>
      <div className="flex max-lg:w-full flex-col w-[50%] gap-y-[30px]">
        <SlideFromBottom style={""}>
          <h1 className="font-semibold text-[33px] max-xl:text-[25px] max-md:text-2xl max-sm:text-xl dark:text-white">
            კომპანია „ARTSPACE“ იყენებს წამყვან პროგრამირების ენებს
          </h1>
        </SlideFromBottom>
        <SlideFromBottom style={""}>
          <p className="text-gray-600">
            კომპანია „ARTSPACE“ იყენებს წამყვან პროგრამირების ენებს და ხელს
            უწყობს უახლესი ტექნოლოგიების დანერგვას საქართველოში. კომპანია
            „Artspace software“ - მა 2023 წელს დააფუძნა კომპანია „Business
            Makers“ - ი რომლის ერთ-ერთი პროდუქტია პლატფორმა - „BMSOFT” - ი BMSOFT
            არის ინოვაციური პლატფორმა, რომელიც მცირე და საშუალო ბიზნესს ეხმარება
            შექმნას ონლაინ მაღაზიის ტიპის ვებ-გვერდი რამდენიმე წუთში და
            განახორციელოს ნებისმიერი სახის პროდუქტის რეალიზაცია ინტერნეტ
            სივრცეში. აღნიშნული პლატფორმა აძლევს საშუალბას ბიზნესს თავად აირჩიოს
            ონლაინ მაღაზიის დიზაინი და ფუნქციონალი.
          </p>
        </SlideFromBottom>
        <SlideFromBottom style={""}>
          <p className="text-gray-600">
            პლატფორმის მთავარი მიზანია გაუადვილოს მცირე და საშუალო ბიზნესის
            ვებ-გვერდის დამზადების პროცესი, დაზოგოს მათი დრო, ენერგია და
            რესურსები. BMSOFT პლატფორმის მთავარი მიზანია რაც შეიძლება მეტი
            ბიზნესის გაციფრულებას შეუწყოს ხელი.
          </p>
        </SlideFromBottom>
      </div>
    </div>
  );
}
