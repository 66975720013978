import { useState } from "react";
import { ArrowRight, BrowserEdge } from "react-bootstrap-icons";
import SlideFromBottom from "../animations/SlideFromBottom";
import Bounce from "../animations/Bounce";
import TiltEffect from "../animations/TiltEffect";
import group1 from "../../imgs/1111.webp";
import group2 from "../../imgs/2222.webp";
import group3 from "../../imgs/3333.webp";
import { Link } from "react-router-dom";

function Thirdpart() {
  const [imageLinks, setImageLinks] = useState([
    {
      i: 1,
      link: group1,
      z: "1",
      style: "top-0 left-0",
      start: -30,
      end: 0,
    },
    {
      i: 2,
      link: group2,
      z: "2",
      style: "top-10 left-10 max-sm:top-5 max-sm:left-5",
      start: 20,
      end: 0,
    },
    {
      i: 3,
      link: group3,
      z: "3",
      style: "top-20 left-40 max-sm:top-10 max-sm:left-20",
      start: -20,
      end: 0,
    },
  ]);

  return (
    <div className="w-[100%] flex justify-center">
      <div className="whybm  flex flex-col gap-y-[40px]">
        <SlideFromBottom>
          <div className="whybmtitlediv flex flex-col items-center mb-[20px]">
            <h1 className="whybmtitle text-[30px] text-center myownbackgr inline-block text-transparent bg-clip-text dark:text-gray-300 duration-200">
              რატომ ირჩევენ BMSOFT-ს
            </h1>
          </div>
        </SlideFromBottom>

        <div className="whybmcont w-[100%] flex gap-[25px] items-start">
          <SlideFromBottom style={"whyslide w-[50%]"}>
            <div
              className="whybmpicts w-[80%] h-[500px] pt-[20px] rounded-[18px] 
            relative translate-x-[-80px] max-lg:w-full max-lg:translate-x-0 max-lg:mb-10"
            >
              {imageLinks.map((item, index) => {
                return (
                  <Bounce
                    key={item.i}
                    start={item.start}
                    end={item.end}
                    style={`whyslidesec absolute w-full z-[${item.z}] ${item.style}`}
                  >
                    <TiltEffect mstyle={`whyslideth w-full`}>
                      <img
                        src={item.link}
                        id={item.i}
                        className={`whysideimg w-[100%]  object-contain`}
                      />
                    </TiltEffect>
                  </Bounce>
                );
              })}
            </div>
          </SlideFromBottom>

          <div className="whybmtext w-[50%] flex flex-col justify-center gap-y-[20px] dark:text-gray-300 duration-200">
            <ul className="whyul text-[15px] flex flex-col gap-y-[5px]">
              <SlideFromBottom>
                <li className="flex gap-[5px] items-center ">
                  <BrowserEdge className="whyicn text-[#85276a] w-[5%]" />
                  <p className="whytext text-[#8c4a79] dark:text-white w-[95%]">
                    გსურთ ვებგვერდი მაგრამ არ გაქვთ დრო, გამოცდილება და რესურსი
                    ნულიდან მის შესაქმნელად ?
                  </p>
                </li>
              </SlideFromBottom>

              <SlideFromBottom>
                <li className="flex gap-[5px] items-center">
                  <BrowserEdge className="whyicn text-[#85276a] w-[5%]" />
                  <p className="whytext  text-[#8c4a79] dark:text-white w-[95%">
                    გაქვთ ვებგვერდი და არ მუშაობს გამართულად ?
                  </p>
                </li>
              </SlideFromBottom>

              <SlideFromBottom>
                <li className="flex gap-[5px] items-center">
                  <BrowserEdge className=" whyicn text-[#85276a] w-[5%] " />
                  <p className="whytext text-[#8c4a79] dark:text-white w-[95%]">
                    გსურთ შეხვიდეთ ონლაინ ბაზარზე და არ იცით საიდან დაიწყოთ ?
                  </p>
                </li>
              </SlideFromBottom>

              <SlideFromBottom>
                <li className="flex gap-[5px] items-center">
                  <BrowserEdge className="whyicn text-[#85276a] w-[5%] " />
                  <p className="whytext text-[#8c4a79] dark:text-white w-[95%]">
                    გსურთ გაზარდოთ გაყიდვები ელექტრონული კომერციის დახმარებით ?
                  </p>
                </li>
              </SlideFromBottom>

              <SlideFromBottom>
                <li className="flex gap-[5px] items-center">
                  <BrowserEdge className="whyicn text-[#85276a] w-[5%] " />
                  <p className="whytext text-[#8c4a79] dark:text-white w-[95%]">
                    გერთულებათ თქვენი არსებული საიტის ფინანსურ, საკურიერო და
                    სააღრიცხვო პროგრამებთან ინტეგრირება ?
                  </p>
                </li>
              </SlideFromBottom>
            </ul>
            <SlideFromBottom>
              <h1 className="whybmtexttitle text-[20px] myownbackgr inline-block text-transparent bg-clip-text duration-200">
                ნუ მისცემთ უფლებას პრობლემებს შეაფერხოს თქვენი ბიზნესი
              </h1>
            </SlideFromBottom>

            <SlideFromBottom>
              <p className="text-[#010125] text-[17px]">
                BMSOFT-ის პლატფორმა გაძლევთ საშუალებას ერთი კლიკით მიიღოთ
                სრულყოფილი ონლაინ მაღაზია თვეში სულ რაღაც 99 ლარად და რამდენიმე
                წელში საბოლოოდ შეისყიდოთ იგი პლატფორმის ფუნქციონალზე სრული
                წვდომით.
              </p>
            </SlideFromBottom>
            <SlideFromBottom>
              <p className="text-[#010125] text-[17px]">
              BMSOFT არის  მარტივი და ლოგიკური გადაწყვეტა ,თუ როგორ  გამოავლინოთ პოტენციალი, გაზარდოთ გაყიდვები და აუდიტორია.
              </p>
            </SlideFromBottom>

            <SlideFromBottom>
              <Link to="/contact">
              <div className="flex w-[160px] h-[35px] cursor-pointer  rounded-[33px] items-center justify-center myownbackgr text-white  gap-[5px]">
                <h1 className="pt-[2px]">შეიძინე აქ</h1>
                <ArrowRight className="" />
              </div>
              </Link>
            </SlideFromBottom>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thirdpart;
