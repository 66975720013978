import { useEffect, useRef, useState } from "react";
import { ChevronDown, Dot } from "react-bootstrap-icons";

const AccordionOneItem = ({
  question,
  answer,
  index,
  tone,
  ttwo,
  tfree,
  tfour,
  tfive,
  par,
  teone,
  tetwo,
  opend,
  filterQuestions,
  card,
}) => {
  //is clicked
  const [isOpend, setIsOpend] = useState(false);

  //change clicked item
  useEffect(() => {
    setIsOpend(opend);
  }, [opend]);

  return (
    <div
      onClick={() => {
        filterQuestions(index);
      }}
      className={`cursor-pointer ${isOpend && "bg-[#f4f2fb] rounded-[10px]"}`}
    >
      <div
        className={`transition-all  ease-linear delay-100  duration-200 flex items-center justify-between w-full p-5 
             ${isOpend && `text-[#85276a]`}`}
      >
        <h1 className="acordionquestion w-[400px]">{question}</h1>
        <ChevronDown
          className={`transition-all ease-linear delay-100 duration-200 `}
        />
      </div>
      <div
        className={`transition-all ease-linear delay-100 duration-200 px-5
                ${
                  isOpend
                    ? card === "one" && "questansvone h-[130px] pb-5"
                    : "h-0 overflow-hidden"
                }
                ${
                  isOpend
                    ? card === "two" && "questansvtwo h-[90px] pb-5"
                    : "h-0 overflow-hidden"
                }
                ${
                  isOpend
                    ? card === "three" && "questansvthree h-[210px] pb-5"
                    : "h-0 overflow-hidden"
                }`}
      >
        <p
          className={`transition-all ease-linear delay-100 duration-200  
                ${isOpend ? "opacity-1 text-[15px] text-[gray]" : "opacity-0"}`}
        >
          {answer}
          <div
            className={`flex-col gap-y-[10px] ${
              card === "three" ? "flex" : "hidden"
            }`}
          >
            <ul className="">
              <li className="flex items-center">
                <Dot />
                {tone}
              </li>
              <li className="flex items-center">
                <Dot />
                {ttwo}
              </li>
              <li className="flex items-center">
                <Dot />
                {tfree}
              </li>
              <li className="flex items-center">
                <Dot />
                {tfour}
              </li>
              <li className="flex items-center">
                <Dot />
                {tfive}
              </li>
            </ul>
            <p className="text-black">{par}</p>
            <ul className="">
              <li className="flex items-center">
                <Dot />
                {teone}
              </li>
              <li className="flex items-center">
                <Dot />
                {tetwo}
              </li>
            </ul>
          </div>
        </p>
      </div>
    </div>
  );
};

export default AccordionOneItem;
