import { Clock, Fonts } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ft from "../../imgs/office4.jpg";
import { useState } from "react";
import SlideFromBottom from "../animations/SlideFromBottom";
import { FacebookProvider, Comments } from "react-facebook";

function EveryBlog() {
  const [active, setActive] = useState("small");

  return (
    <div className="w-[100%] pt-[20px] flex flex-col gap-y-[20px]">
      <SlideFromBottom>
        <p className="inblogtitle text-[34px] font-semibold dark:text-white duration-200">
          „დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი
        </p>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex gap-[20px]">
          <div className="flex items-center gap-[8px] dark:text-white duration-200">
            <div>
              <Clock />
            </div>
            <p>0:46</p>
          </div>
          <Link to="https://www.facebook.com/sharer/sharer.php?u=https://www.example.com/', '_blank">
            <div className="bg-gradient-to-r from-[#004db5] to-[#010125] rounded-[33px] flex text-white gap-[10px] items-center px-[12px] cursor-pointer">
              <div>
                <p className="font-semibold text-[20px]">f</p>
              </div>
              <p>გაზიარება</p>
            </div>
          </Link>
        </div>
      </SlideFromBottom>
      <SlideFromBottom style={"w-[95%] h-[60vh]"}>
        <div className="inblogpht w-full h-full">
          <img src={ft} className="w-[100%] h-[100%] object-cover" alt="" />
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex gap-[5px] text-[20px] dark:text-white duration-200">
          <Fonts
            className="text-[16px] cursor-pointer"
            onClick={() => {
              setActive("small");
            }}
          />
          <Fonts
            className="text-[20px] cursor-pointer"
            onClick={() => {
              setActive("mid");
            }}
          />
          <Fonts
            className="text-[24px] cursor-pointer"
            onClick={() => {
              setActive("larg");
            }}
          />
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <p
          className={`inblogparagraph dark:text-white duration-200 w-[95%] ${
            active == "small"
              ? "text-[16px]"
              : active == "mid"
              ? "text-[20px]"
              : active == "larg" && "text-[24px]"
          }`}
        >
          ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას,
          ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას.ნუ
          აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას,
          ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას.ნუ
          აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას,
          ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას.
        </p>
      </SlideFromBottom>
      <SlideFromBottom>
        <FacebookProvider appId="691279866105721">
          <Comments href="http://example.com" />
        </FacebookProvider>
      </SlideFromBottom>
    </div>
  );
}

export default EveryBlog;
