import {
  CCircle,
  CaretRight,
  Envelope,
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
} from "react-bootstrap-icons";
import sitelogo from "../imgs/300.webp";
import payments from "../imgs/989898.webp"
import SlideFromBottom from "./animations/SlideFromBottom";

function Footer({goToTopSmooth}) {
  return (
    <div className="w-[100%] flex flex-col items-center bg-[#010120] text-white ">
      <div className="footer-top w-[100%] max-w-[1519px] px-[10%] flex flex-wrap justify-between py-[50px]">
        <SlideFromBottom>
          <div className="footer-left-col gap-[20px] flex flex-col">
            <div
              className="w-[150px] cursor-pointer pr-[10px] rounded-[33px]"
              onClick={goToTopSmooth}
            >
              <img
                src={sitelogo}
                className="w-[100%] h-[100%] object-contain"
              />
            </div>
            <p className="ftr-lft-p w-[300px]">
              წარმატების გზა, რომელიც კოდის ერთი ხაზით იწყება
            </p>
            <div className="flex gap-[10px]">
              <div className="w-[30px] h-[30px] rounded-full border-[1px] cursor-pointer flex items-center justify-center myownbackgrhover duration-200">
                <Facebook />
              </div>
              <div className="w-[30px] h-[30px] rounded-full border-[1px]  cursor-pointer flex items-center justify-center myownbackgrhover duration-200">
                <Linkedin />
              </div>
              <div className="w-[30px] h-[30px] rounded-full border-[1px]  cursor-pointer flex items-center justify-center myownbackgrhover duration-200">
                <Instagram />
              </div>
              <div className="w-[30px] h-[30px] rounded-full border-[1px]  cursor-pointer flex items-center justify-center myownbackgrhover duration-200">
                <Youtube />
              </div>
            </div>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className="flex flex-col gap-[20px]">
            <h1>ნავიგაცია</h1>
            <ul className="flex flex-col gap-[5px]">
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>მთავარი</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>პროდუქტები</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>სერვისები</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>ჩვენს შესახებ</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>კონტაქტი</p>
              </li>
            </ul>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className="flex flex-col gap-[20px]">
            <h1>დამატებითი ბმულები</h1>
            <ul className="flex flex-col gap-[5px]">
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>კონფიდენციალურობის პოლიტიკა</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>წესები და პირობები</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>დოკუმენტაცია</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>საგადახდო სისტემები</p>
              </li>
              <li className="flex items-center gap-[5px] cursor-pointer hover:text-[#85276a] duration-200">
                <CaretRight />
                <p>მიწოდების პოლიტიკა</p>
              </li>
            </ul>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className="flex flex-col gap-[20px]">
            <h1>სიახლეების გამოწერა</h1>
            <div className="flex flex-col gap-[10px]">
              <p>თქვენი ელ-ფოსტა*</p>
              <div className="ftr-email w-[200px] h-[40px] flex items-center gap-[5px] rounded-[33px] bg-gray-100 text-black pl-[15px] pr-[10px]">
                <Envelope className="text-[17px] text-gray-500" />
                <input
                  className="w-[95%] px-[5px] outline-none bg-gray-100"
                  placeholder="ელ-ფოსტა"
                />
              </div>
              <div className="ftr-sbscrb w-[200px] h-[40px] flex justify-center cursor-pointer text-[19px] pt-[2px] items-center rounded-[33px] myownbackgr duration-200">
                <h1 className="">გამოწერა</h1>
              </div>
            </div>
          </div>
        </SlideFromBottom>
      </div>
      <div className="footer-bottom w-[100%] max-w-[1519px] px-[10%] border-t-[1px] border-gray-700 justify-between items-center flex py-[15px]">
        <div className="flex gap-[3px] items-center">
          <CCircle className="text-[18px] pb-[2px]" />
          <p>Developed By Artspace</p>
        </div>
        <div>
          <img src={payments} className="w-[220px] max-sm:w-[170px]" alt="" />
        </div>
      </div>
    </div>
  );
}
export default Footer;
