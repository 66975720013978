import InServCard from "../servicespage/innerservices/inservcard";
import pht from "../../imgs/100.webp"
import pht2 from "../../imgs/200.webp";
import Waves from "../animations/Waves";
import SlideFromBottom from "../animations/SlideFromBottom";
import ContactService from "../servicespage/contactinservice";

export default function InnerServicefour() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <div className="fixed w-[100%] h-[100%] top-0 left-0 z-[-20]">
        <Waves />
      </div>
      <div className="w-full mt-[70px] max-w-[1519px] px-[10%] flex flex-col py-20 max-sm:py-10 gap-y-20 max-sm:gap-y-10">
        <h1
          className="text-center text-[35px] text-[#010125]
                max-md:text-2xl max-sm:text-xl"
        >
          გადახდის სისტემების ინტეგრაცია
        </h1>
        <InServCard
          foto={pht}
          text={
            "გახადეთ გადახდის პროცესი უფრო სწრაფი, მოსახერხებელი და უსაფრთხო თქვენი მომხმარებლებისთვის BMSOFT პლატფორმაზე გადახდის სისტემების ინტეგრაციით."
          }
          texttwo={
            "პოპულარულ საბანკო ინსტიტუტებთან შეუფერხებლი კავშირით BMSOFT პლატფორმაზე გადახდის სისტემების ინტეგრაციით, თქვენ გაზრდით მომხმარებელთა კმაყოფილებსა და გაყიდვებს  უსაფრთხო და მარტივი გადახდის მეთოდების შეთავაზებით."
          }
          textthree={
            "სანდო ფინანსურ ინსტიტუტებთან პარტნიორობით, ჩვენ უზრუნველვყოფთ, რომ თქვენმა მომხმარებლებმა შეასრულონ ონლაინ ტრანზაქციები ძალისხმევის გარეშე, რითაც იღებენ კომფორტს რომელსაც იმსახურებენ."
          }
          step={"ტრანზაქციების გამარტივება და გაყიდვების გაზრდა"}
        />
        <InServCard
          rev={true}
          foto={pht2}
          text={""}
          step={"გადახდის მეთოდები BMSOFT პლატფორმაზე"}
        />
        <SlideFromBottom>
          <ContactService />
        </SlideFromBottom>
      </div>
    </div>
  );
}