import { useState, useEffect } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "../../imgs/0222222.webp";
import img1 from "../../imgs/0333333.webp"

function PricingSlider() {
  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] >= 1224) {
      setSlidesToDisplay(4);
    } else if (windowSize[0] <= 1224 && windowSize[0] >= 1024) {
      setSlidesToDisplay(3);
    } else if (windowSize[0] <= 1024 && windowSize[0] >= 724) {
      setSlidesToDisplay(2);
    } else if (windowSize[0] <= 724 && windowSize[0] >= 0) {
      setSlidesToDisplay(2);
    }
  }, [windowSize]);

  return (
    <div className={"w-full pointer-events-none flex flex-col gap-10 pb-20 max-md:pb-10"}>
      <Swiper
        slidesPerView={slideToDisplay}
        spaceBetween={30}
        className={"w-full"}
        speed={2000}
        cssease={"linear"}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 0,
        }}
        disabledclass={"disabled_swiper_button"}

      >
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
      </Swiper>
      <Swiper
        slidesPerView={slideToDisplay}
        spaceBetween={30}
        className={"w-full"}
        speed={2000}
        cssease={"linear"}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 0,
          reverseDirection: true,
        }}
        disabledclass={"disabled_swiper_button"}
      >
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default PricingSlider;
