import InServCard from "../servicespage/innerservices/inservcard";
import pht from "../../imgs/100.webp";
import pht2 from "../../imgs/200.webp";
import Waves from "../animations/Waves";
import SlideFromBottom from "../animations/SlideFromBottom";
import ContactService from "../servicespage/contactinservice";

export default function InnerServicefive() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <div className="fixed w-[100%] h-[100%] top-0 left-0 z-[-20]">
        <Waves />
      </div>
      <div className="w-full mt-[70px] max-w-[1519px] px-[10%] flex flex-col py-20 max-sm:py-10 gap-y-20 max-sm:gap-y-10">
        <h1
          className="text-center text-[35px] text-[#010125]
                max-md:text-2xl max-sm:text-xl"
        >
          ლოიალობის სისტემები
        </h1>
        <InServCard
          foto={pht}
          text={
            "BMSOFT - მა შეიმუშავა თქვენი ბიზნესისთვის ძლიერი ლოიალობის სისტემა, როგორც ჩვენი პლატფორმის ნაწილი, რომელიც საშუალებას გაძლევთ წაახალისოთ თქვენი მომხმარებლები სისტემა შექმნილია მომხმარებლის კმაყოფილების გასაძლიერებლად და ბრენდის პოპულარიზაციისთის."
          }
          texttwo={
            "ამ ფუნქციის განხორციელებით, თქვენ შეგიძლიათ შექმნათ პერსონალიზებული, თქვენი ბიზნესის საჭიროებებზე მორგებული ლოიალობის სისტემა. ეს შეიძლება იყოს, ქულების დაგროვება, ექსკლუზიური ფასდაკლებები, სპეციალური აქციები და ა.შ"
          }
          step={"წაახალისეთ თქვენი მომხმარებლები"}
        />
        <InServCard
          rev={true}
          foto={pht2}
          text={
            "ამით ხელს შეუწყობთ განმეორებით შესყიდვებს, მომხმარებელთა ჩართულობას და მათ დამეგობრებას. ის არა მხოლოდ ახალი მომხმარებლების მოზიდვას უწყობს ხელს, არამედ აძლიერებს არსებულ ურთიერთობებს, აქცევს ერთჯერად მყიდველებს ბრენდის ერთგულ მომხმარებლებად."
          }
          texttwo={
            "ამრიგად გირჩევთ , გააძლიერთ თქვენი ბრენდი BMSOFT - ის პლატფორმის ლოიალობის სისტემის საშუალებით და ხელი შეუწყეთ გრძელვადიანი ურთიერთობის დამყარებას თქვენს მომხმარებლებთან. შემოგვიერთდით დღეს, აიყვანეთ თქვენი ბიზნესი ახალ სიმაღლეებზე."
          }
          step={""}
        />
        <SlideFromBottom>
          <ContactService />
        </SlideFromBottom>
      </div>
    </div>
  );
}
