import { Amd, Plugin, SdCard } from "react-bootstrap-icons";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsRocket } from "react-icons/bs";

export default function NewCardOne({
  cardtitle,
  month,
  txt1,
  txt2,
  txt3,
  txt4,
  txt5,
  txt6,
}) {
  return (
    <div className="">
      <h1
        className={`w-[100%] h-[50px] items-center rounded-t-[5px] justify-center myownbackgr text-white ${
          cardtitle === "მედიუმი" ? "flex" : "hidden"
        }`}
      >
        პოპულარული
      </h1>
      <div
        className={`packcard w-full min-w-[280px] border-[1px]  px-[50px] flex flex-col gap-y-[20px] ${
          cardtitle === "მედიუმი"
            ? "bg-[#f8f7fd] pb-[50px] pt-[20px] rounded-b-[5px]"
            : "bg-white pb-[50px] pt-[70px] rounded-[5px]"
        }`}
      >
        <h1 className="font-semibold text-[22px]">{cardtitle}</h1>
        <div className="border-b-[1px] pb-[20px]">
          <div className="flex">
            <h1 className="text-[30px] duration-200">
              {month
                ? cardtitle === "სტარტაპი"
                  ? "99₾"
                  : cardtitle === "მედიუმი"
                  ? "149₾"
                  : cardtitle === "ენტერპრაისი" && "219₾"
                : cardtitle === "სტარტაპი"
                ? "129₾"
                : cardtitle === "მედიუმი"
                ? "189₾"
                : cardtitle === "ენტერპრაისი" && "289₾"}
            </h1>
            <p className="flex items-end pb-[8px] text-gray-500 duration-200">
              {month ? "/თვე" : "-დან"}
            </p>
          </div>
        </div>

        <div className="packcardtexth flex flex-col h-[400px] justify-between">
          <ul className="flex flex-col gap-y-[15px]">
            <li className="flex items-center gap-[10px]">
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <SdCard />
              </div>
              <p className="font-semibold w-[87%]">{txt1}</p>
            </li>
            <li className="flex items-center gap-[10px]">
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <Amd />
              </div>
              <p className="font-semibold w-[87%]">{txt2}</p>
            </li>
            <li className="flex items-center gap-[10px]">
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <Plugin />
              </div>
              <p className="font-semibold w-[87%]">{txt3}</p>
            </li>
            <li className="flex items-center gap-[10px]">
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <AiOutlineSafetyCertificate />
              </div>
              <p className="font-semibold w-[87%]">{txt4}</p>
            </li>
            <li className="flex items-center gap-[10px]">
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <BsRocket />
              </div>
              <p className="font-semibold w-[87%]">{txt5}</p>
            </li>
            <li
              className={`items-center gap-[10px] ${
                cardtitle === "სტარტაპი" ? "hidden" : "flex"
              } `}
            >
              <div className="w-[30px] h-[30px] text-white myownbackgr flex items-center justify-center rounded-full">
                <p>Rs</p>
              </div>
              <p className="font-semibold w-[87%]">{txt6}</p>
            </li>
          </ul>
          <h1 className="w-[150px] h-[40px] flex flex-col items-center justify-center font-semibold border-[#013057] cursor-pointer myownbackgrhover hover:text-white duration-200 rounded-[33px] border-[1px]">
            ყიდვა
          </h1>
        </div>
      </div>
    </div>
  );
}
