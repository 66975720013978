export default function ServicesCard({ img, title, text, handleservpop }) {
  return (
    <div
      class="servicesCard w-[100%]  flex flex-col justify-between gap-y-[20px] px-[10px] py-[20px] rounded-[33px]  h-[100%] bg-[#f0f1f9] dark:shadow-gray-300 duration-200 items-center"
      onClick={handleservpop}
    >
      <div class="servcardtopicon w-[100px] h-[110px] flex items-center justify-center bg-gray-100  text-[30px] text-white dark:bg-[#c2bfff]">
        <img src={img} className="w-[100%] h-[100%] object-cover" />
      </div>
      <p class="servicecardtitle text-[15px] cursor-pointer hover:text-[#4d1dc1] duration-200 dark:text-[#010125] text-center w-[100%]">
        {title}
      </p>
      <div className="flex flex-col gap-y-[10px]">
        <p class="servcardtext text-gray-400 text-center text-[12px]">{text}</p>
      </div>
    </div>
  );
}
