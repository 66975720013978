import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { CaretDown, X } from "react-bootstrap-icons";
import SlideFromBottom from "../animations/SlideFromBottom";

export default function ContactField() {
  const outdropedRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (outdropedRef.current && !outdropedRef.current.contains(event.target)) {
      setDroped(false);
    }
  }

  const [clicked, setClicked] = useState("პრიორიტეტი");

  const [droped, setDroped] = useState(false);
  const handleDroped = () => {
    setDroped((pre) => !pre);
  };
  return (
    <div className="w-[100%] myownbackgr rounded-[35px] pt-[15px]">
      <div className="w-[100%] h-[600px] max-xl:h-[1000px]  max-xl:flex-col bg-gray-100 rounded-[30px] py-[50px] gap-[40px] px-[5%] flex items-center gap-y-[20px] relative">
        <div className="w-[50%] max-xl:w-full flex flex-col items-center gap-y-[20px]">
          <SlideFromBottom>
            <div className=" flex flex-col items-center">
              <h1 className=" text-[30px] myownbackgr inline-block text-transparent bg-clip-text duration-200">
                მოგვწერეთ
              </h1>
            </div>
          </SlideFromBottom>
          <div className="flex w-[100%] max-sm:flex-col justify-between gap-[20px]">
            <input
              type="text"
              placeholder="სახელი"
              className="w-[48%] h-[50px] max-sm:w-full flex items-center rounded-[33px] px-[20px] outline-none focus:border-[blue] border-[1px] border-gray-200"
            />
            <input
              type="text"
              placeholder="ელ-ფოსტა*"
              className="w-[48%] h-[50px] max-sm:w-full flex items-center rounded-[33px] px-[20px] outline-none focus:border-[blue] border-[1px] border-gray-200"
            />
          </div>
          <div className="flex w-[100%] max-sm:flex-col justify-between gap-[20px]">
            <input
              type="text"
              placeholder="ტელეფონის ნომერი"
              className="w-[48%] h-[50px] max-sm:w-full flex items-center rounded-[33px] px-[20px] outline-none focus:border-[blue] border-[1px] border-gray-200"
            />
            <input
              type="text"
              placeholder="შენი საიტი"
              className="w-[48%] h-[50px] max-sm:w-full flex items-center rounded-[33px] px-[20px] outline-none focus:border-[blue] border-[1px] border-gray-200"
            />
          </div>
          <div ref={outdropedRef} className="relative w-[100%]">
            <div
              className={`px-[20px] h-[50px] flex  justify-between items-center cursor-pointer border-[1px] bg-white rounded-[33px] duration-150 w-[100%] ${
                droped ? "border-[blue]" : ""
              }`}
              onClick={handleDroped}
            >
              <div className="flex justify-between items-center  w-[100%]">
                <p>{clicked}</p>
                <X
                  className={`text-[red] ${
                    clicked != "პრიორიტეტი" ? "flex" : "hidden"
                  }`}
                  onClick={() => {
                    setClicked("პრიორიტეტი");
                  }}
                />
              </div>
              <CaretDown
                className={`duration-200 ${droped ? "rotate-180" : ""}`}
              />
            </div>

            <div
              className={`filterScrlbar myshadow absolute z-[5] top-[55px] w-[100%] overflow-auto bg-white rounded-b-[13px] ${
                droped ? "flex" : "hidden"
              }`}
            >
              <ul className="w-[100%]">
                <li
                  className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                  onClick={() => {
                    handleDroped();
                    setClicked("პრიორიტეტი");
                  }}
                >
                  პრიორიტეტი
                </li>
                <li
                  className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                  onClick={() => {
                    handleDroped();
                    setClicked("მომხმარებელი");
                  }}
                >
                  მაღალი
                </li>
                <li
                  className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                  onClick={() => {
                    handleDroped();
                    setClicked("მაკლერი");
                  }}
                >
                  საშუალო
                </li>
              </ul>
            </div>
          </div>
          <textarea
            className="w-[100%] h-[150px] resize-none rounded-[33px] px-[20px] py-[15px] bg-white outline-none focus:border-[blue] border-[1px] border-gray-200"
            placeholder="როგორ შეგვიძლია დაგეხმაროთ?"
          ></textarea>
          <p
            className="px-[20px] h-[40px] pb-[2px] rounded-[33px] myownbackgr 
          text-white cursor-pointer flex items-center font-semibold"
          >
            გაგზავნა
          </p>
        </div>
        <div className="w-[50%] max-xl:w-full h-[100%]  justify-end items-center rounded-[20px] dark:bg-[#010125]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11911.732647077237!2d44.7697635!3d41.7219611!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473177c179825%3A0x720c3a1f563c7168!2z4YOh4YOQ4YOR4YOj4YOg4YOX4YOQ4YOa4YOdLCDhg5fhg5Hhg5jhg5rhg5jhg6Hhg5g!5e0!3m2!1ska!2sge!4v1683365338330!5m2!1ska!2sge"
            width="100%"
            height="100%"
            className="rounded-[20px] "
          ></iframe>
        </div>
      </div>
    </div>
  );
}
