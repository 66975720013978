import { CaretRightFill } from "react-bootstrap-icons";
import SlideFromBottom from "../animations/SlideFromBottom";
import Lottie from "react-lottie";
import One from "../LottieAnimations/LjnOsk5FhF.json";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScreenSize from "../customHooks/useScreenSize";

function FirstPart() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: One,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const screenSize = useScreenSize();

  const [imageDimensions, setImageDimensions] = useState({
    x: 500,
    y: 500,
  });

  useEffect(() => {
    if (screenSize >= 1420) {
      setImageDimensions({
        y: 460,
      });
    } else if (screenSize >= 1390 && screenSize <= 1420) {
      setImageDimensions({
        y: 450,
      });
    } else if (screenSize >= 1280 && screenSize <= 1390) {
      setImageDimensions({
        x: 460,
        y: 380,
      });
    } else if (screenSize >= 1180 && screenSize <= 1280) {
      setImageDimensions({
        x: 400,
        y: 300,
      });
    } else if (screenSize >= 300 && screenSize <= 450) {
      setImageDimensions({
        x: 300,
        y: 250,
      });
    }
  }, [screenSize]);
  // end screen width
  return (
    <div className="w-[100%]  flex justify-center overflow-hidden ">
      <SlideFromBottom>
        <div className="mainbaner  flex items-end justify-between">
          <div className="mainbanerleft w-[40%] flex  flex-col pb-[200px]">
            <h1 className="mainbanertitle mb-[20px] text-[50px] leading-[4rem] dark:text-gray-300 duration-200">
              {" "}
              ერთი ნაბიჯი{" "}
              <span className="myownbackgr inline-block	 text-transparent bg-clip-text  duration-200">
                მომხმარებლამდე
              </span>
            </h1>
            <p className="mainbanertext text-[18px] text-gray-500">
              BMSOFT პლატფორმა გეხმარებით შექმნათ და გაზარდოთ ონლაინ ბიზნესი -
              „პირველი გაყიდული ნივთიდან პირველ მილიონამდე„
            </p>
            <div className="mainbtnstop flex items-center gap-[10px] mt-[20px]">
              <div className="maintrybtn w-[200px] h-[40px] rounded-[33px] pt-[3px]  text-white myownbackgr flex items-center justify-center cursor-pointer duration-200">
                <h1>სცადე უფასოდ</h1>
              </div>
              <Link to="/instructions">
                <div className="flex items-center gap-[10px] group cursor-pointer">
                  <div className="instrbtn w-[40px] h-[40px] rounded-full flex items-center justify-center myownbackgr text-white hover:bg-[#362dce] duration-200">
                    <CaretRightFill />
                  </div>
                  <h1 className="instrtext dark:text-gray-300 duration-200 group-hover:text-[#85276a]">
                    ინსტრუქცია
                  </h1>
                </div>
              </Link>
            </div>
          </div>
          <div className="mainbanerright w-[50%] h-[77vh] pointer-events-none pt-[50px] flex justify-center items-center relative">
            <Lottie
              options={defaultOptions}
              height={imageDimensions.y}
              width={imageDimensions.x}
            />
          </div>
        </div>
      </SlideFromBottom>
    </div>
  );
}

export default FirstPart;
