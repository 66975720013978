import { useState, useEffect } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HostingCard from "./HostingCard";

function HostingSlider() {
  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] >= 1224) {
      setSlidesToDisplay(5);
    } else if (windowSize[0] <= 1224 && windowSize[0] >= 1024) {
      setSlidesToDisplay(4);
    } else if (windowSize[0] <= 1024 && windowSize[0] >= 724) {
      setSlidesToDisplay(3);
    } else if (windowSize[0] <= 724 && windowSize[0] >= 512) {
      setSlidesToDisplay(2);
    } else if (windowSize[0] <= 512 && windowSize[0] >= 0) {
      setSlidesToDisplay(1);
    }
  }, [windowSize]);


  const slides = Array.from({ length: 20 }, (_, index) => (
    <SwiperSlide key={index}>
      <HostingCard />
    </SwiperSlide>
  ));
  

  return (
    <div className={"w-full"}>
      <Swiper
        slidesPerView={slideToDisplay}
        spaceBetween={30}
        className={"w-full"}
        speed={2000}
        cssease={"linear"}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        disabledclass={"disabled_swiper_button"}
      >
        {slides}
      </Swiper>
    </div>
  );
}

export default HostingSlider;
